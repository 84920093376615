<template>
    <section>
        <div class="row py-2 mx-0 px-5 bg-pink text-white align-items-center justify-content-between">
            <img height="63" src="/img/modales/yourmarket_blanco.svg" alt="" />
            <p class="cr-pointer">Cerrar sesión</p>
        </div>
        <div class="d-middle bg-white" style="height: calc(100vh - 80px);">
            <div class="col-6 f-18 text-center mx-auto">
                <img height="150" src="/img/icons/emotes/blueberry.svg" alt="" />
                <p class="f-20 f-600 my-2">Su tienda actualmente está inactiva</p>
                <p>"YourMarket" ha tomado la desición de inactivar la tienda.</p>
                <p class="my-3">Si tiene alguna duda o inquietud le sugerimos comunicarse con el administrador a través del chat.</p>
            </div>
            <div class="h-100 bg-whitesmoke" style="width:380px;">
                <chat />
            </div>
        </div>
    </section>
</template>

<script>
export default {
    layout: 'login'
}
</script>

<style lang="css" scoped>
.bg-pink{
    background: #D6017B;
}
</style>
