import axios from 'axios'

const API = "empacadores"

const ENDPOINTS = {
    getEmpacadores(){
        return axios.get(`${API}/listado`)
    },
    getCedis(){
        return axios.get(`${API}/cedis`)
    },
    postEmpacador(form){
        return axios.post(`${API}/crear`,form)
    },
    getEmpacador(id){
        return axios.get(`${API}/${id}/detalle`)
    },
    deleteEmpacador(id){
        return axios.delete(`${API}/${id}/eliminar`)
    },
    getEmpacadorEditar(id){
        return axios.get(`${API}/${id}/info-editar`)
    },
    deleteCedi(id_user, id_cedi){
        return axios.delete(`${API}/${id_user}/eliminar/${id_cedi}/cedis`)
    },
    putEmpacador(id, form){
        return axios.put(`${API}/${id}/editar`,form)
    }
}
export default ENDPOINTS