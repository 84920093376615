<template>
    <section>
        <div class="row mx-0 align-items-center mb-3">
            <div class="btn-back" @click="$router.push({ name: 'admin.publicidad.mensaje-notificacion.campana', params: { id_campana: $route.params.id_campana} })">
                <i class="icon-left f-12" />
            </div>
            <div class="col-auto text-general f-18 f-500">
                {{ campana.nombre }}
            </div>
        </div>
        <div class="row mx-0 mt-3 bg-white py-4 br-12">
            <div class="col f-18 text-general f-600">
                Destinatarios
            </div>
            <div class="col-3">
                <el-input v-model="buscar" size="small" class="w-100 br-20" placeholder="Buscar usuario" />
            </div>
            <div class="col-12 px-0 mt-3">
                <el-table
                :data="usuarios"
                style="width: 100%"
                header-row-class-name="text-general f-16 text-center"
                >
                    <el-table-column
                    prop="nombre"
                    label="Nombre"
                    class-name="text-center text-general"
                    width="180"
                    />
                    <el-table-column
                    prop="push"
                    label="Envíos push"
                    class-name="text-center text-general"
                    width="180"
                    />
                    <el-table-column
                    prop="mensaje"
                    label="Envíos SMS"
                    class-name="text-center text-general"
                    />
                    <el-table-column
                    prop="apertura_push"
                    label="Apertura PUSH"
                    class-name="text-center text-general"
                    >
                        <template slot-scope="{row}">
                            {{ row.push > 0 ? `${row.apertura}%` : '-' }}
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="tienda"
                    :label="`${$config.vendedor} principal`"
                    class-name="text-center text-general"
                    />
                    <el-table-column
                    prop="cedis"
                    label="Cedis"
                    class-name="text-center text-general"
                    />
                </el-table>
            </div>
        </div>
    </section>
</template>

<script>
import notificaciones from '~/services/publicidad/notificaciones'
export default {
    data(){
        return {
            buscar: '',
            usuarios: [],
            campana: {}
        }
    },
    mounted(){
        this.usuariosCampana()
    },
    methods: {
        async usuariosCampana(){
            try {
                const { data } = await notificaciones.usuariosCampana(this.$route.params.id_campana);
                this.usuarios = data.usuarios
                this.campana = data.campana
            } catch (error){
                this.error_catch(error)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.btn-back{
    width: 20px;
    height: 20px;
    color: #FFFFFF;
    background-color: #000000;
}
</style>