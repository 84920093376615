<template>
    <section>
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 184px);">
            <div class="row mx-0 align-items-center my-5">
                <div class="col text-center text-general f-600 f-18">
                    Estadísticas de acceso de todos los {{ $config.vendedor }}
                </div>
                <div class="col-auto text-right text-general">
                    <div style="width:210px;">
                        Puedes filtrar los datos por un {{ $config.vendedor }} específico
                    </div>
                </div>
                <div class="col-3">
                    <el-select
                    v-model="id_leechero"
                    filterable
                    clearable
                    remote
                    class="w-100"
                    reserve-keyword
                    :remote-method="remoteMethod"
                    >
                        <el-option
                        v-for="item in leecherosSelect"
                        :key="item.id"
                        :label="`${item.nombre} - ${item.nombre_cedis}`"
                        :value="item.id"
                        />
                    </el-select>
                </div>
                <div class="col-auto">
                    <div class="bg-general text-white px-2 py-1 br-10 cr-pointer" @click="montar">
                        Filtrar
                    </div>
                </div>
            </div>
            <div class="row mx-0">
                <div class="col px-0">
                    <p class="text-general f-600 f-18 my-3 text-center mb-4">
                        Acceso de los {{ $config.vendedor + 's' }} a través del sistema en los últimos 45 días
                    </p>
                </div>
                <div class="col-12 d-flex justify-content-end">
                    <div class="col-auto d-middle">
                        <span class="mx-2 text-general">
                            Ver datos desde
                        </span>
                        <div class="d-flex br-8 border-black bg-white">
                            <div class="br-l-8 filter-button d-middle-center" :class="tipoDias == 1 ? 'bg-general3 text-white' : 'text-general'" @click="tipoDias = 1">
                                <i class="icon-web" />
                            </div>
                            <div class="filter-button d-middle-center" :class="tipoDias == 2 ? 'bg-general3 text-white' : 'text-general'" style="border-left:1px solid #000000;border-right:1px solid #000000;" @click="tipoDias = 2">
                                <i class="icon-celular" />
                            </div>
                            <!-- <div class="br-r-8 filter-button d-middle-center" :class="tipoDias == 3 ? 'bg-general3 text-white' : 'text-general'" @click="tipoDias = 3">
                                <i class="icon-apple" />
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <echart :options="accesoLeecherosDias" />
                    <div v-if="_.sumBy(accesoLeecherosDias.dataset.source,'leecheros') == 0 && _.sumBy(accesoLeecherosDias.dataset.source,'interacciones') == 0" class="position-absolute h-100 w-100" style="top:0px;left:0px;opacity:0.8;filter:blur(6px);background-color:#ffffff;z-index:1;" />
                    <div v-if="_.sumBy(accesoLeecherosDias.dataset.source,'leecheros') == 0 && _.sumBy(accesoLeecherosDias.dataset.source,'interacciones') == 0" class="img-sindatos text-center">
                        <img class="obj-cover" src="/img/otros/no_datos.svg" />
                    </div>
                </div>
            </div>
            <div class="row mx-0 my-4 justify-center">
                <div class="col-auto">
                    <div class="bg-light-f5 text-general f-17 br-12 py-2 px-4 shadow">
                        Interacciones totales
                        <b class="f-18">
                            {{ agregarSeparadoresNumero(diasTotales.interacciones) }}
                        </b>
                    </div>
                </div>
                <div class="col-auto">
                    <div class="bg-light-f5 text-general f-17 br-12 py-2 px-4 shadow">
                        Usuarios diferentes que ingresaron
                        <b class="f-18">
                            {{ agregarSeparadoresNumero(diasTotales.leecheros) }}
                        </b>
                    </div>
                </div>
            </div>
            <div class="row mx-0 mt-5">
                <div class="col px-0">
                    <p class="text-general f-600 f-18 my-3 text-center mb-4">
                        Acceso de los {{ $config.vendedor + 's' }} a través del sistema en los últimos 12 meses
                    </p>
                </div>
                <div class="col-12 d-flex justify-content-end">
                    <div class="col-auto d-middle">
                        <span class="mx-2 text-general">
                            Ver datos desde
                        </span>
                        <div class="d-flex br-8 border-black bg-white">
                            <div class="br-l-8 filter-button d-middle-center" :class="tipoMeses == 1 ? 'bg-general3 text-white' : 'text-general'" @click="tipoMeses = 1">
                                <i class="icon-web" />
                            </div>
                            <div class="filter-button d-middle-center" :class="tipoMeses == 2 ? 'bg-general3 text-white' : 'text-general'" style="border-left:1px solid #000000;border-right:1px solid #000000;" @click="tipoMeses = 2">
                                <i class="icon-celular" />
                            </div>
                            <!-- <div class="br-r-8 filter-button d-middle-center" :class="tipoMeses == 3 ? 'bg-general3 text-white' : 'text-general'" @click="tipoMeses = 3">
                                <i class="icon-apple" />
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex mx-0 my-5">
                <div class="px-0 ml-3 d-flex flex-column justify-content-center" style="width:150px;">
                    <div class="bg-light-f5 shadow br-10 text-general px-3 py-2">
                        <p class="text-center">
                            Mes con más accesos
                        </p>
                        <p class="f-600 text-center">
                            {{ agregarSeparadoresNumero(maxMeses.leecheros.leecheros) }}
                        </p>
                        <p class="text-center">
                            {{ maxMeses.leecheros.mes }}
                        </p>
                    </div>
                    <div class="bg-light-f5 shadow br-10 text-general px-3 py-2 my-2">
                        <p class="text-center">
                            Mes con más interacciones
                        </p>
                        <p class="f-600 text-center">
                            {{ agregarSeparadoresNumero(maxMeses.interacciones.interacciones) }}
                        </p>
                        <p class="text-center">
                            {{ maxMeses.interacciones.mes }}
                        </p>
                    </div>
                </div>
                <div class="col">
                    <echart :options="accesoLeecherosMeses" />
                    <div v-if="_.sumBy(accesoLeecherosMeses.dataset.source,'leecheros') == 0 && _.sumBy(accesoLeecherosMeses.dataset.source,'interacciones') == 0" class="position-absolute h-100 w-100" style="top:0px;left:0px;opacity:0.8;filter:blur(6px);background-color:#ffffff;z-index:1;" />
                    <div v-if="_.sumBy(accesoLeecherosMeses.dataset.source,'leecheros') == 0 && _.sumBy(accesoLeecherosMeses.dataset.source,'interacciones') == 0" class="img-sindatos text-center">
                        <img class="obj-cover" src="/img/otros/no_datos.svg" />
                    </div>
                </div>
            </div>
            <div class="row mx-0 align-items-center">
                <div class="col-auto d-middle text-general f-15">
                    Rango de análisis
                </div>
                <div class="col-auto" style="width:174px;">
                    <el-select v-model="rango" placeholder="Seleccionar" size="small">
                        <el-option
                        v-for="item in rangos"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.id"
                        />
                    </el-select>
                </div>
                <div class="col-auto px-0 mx-2" style="width:248px;">
                    <div class="date-picker-edit">
                        <el-date-picker
                        v-model="fecha"
                        class="w-100"
                        size="small"
                        format="dd-MM-yyyy"
                        value-format="yyyy-MM-dd"
                        :disabled="disable_fecha"
                        popper-class="date-picker-edit-popper"
                        type="daterange"
                        unlink-panels
                        start-placeholder="Fecha inicio"
                        end-placeholder="Fecha fin"
                        />
                    </div>
                </div>
                <div class="col-auto px-0">
                    <div class="btn-general px-3 f-14" @click="informe">
                        Mostrar Informe
                    </div>
                </div>
                <cargando v-if="cargandoInforme" mini />
            </div>
            <div class="row mx-0 mt-5 mb-2">
                <div class="col px-5 text-general f-18 f-600">
                    Acceso por horas de los días analizados
                </div>
            </div>
            <!-- Chart Promedio -->
            <div class="d-flex mx-0 my-5">
                <div class="px-0 ml-3 d-flex flex-column justify-content-center" style="width:150px;">
                    <div class="bg-light-f5 shadow br-10 text-general px-3 py-2">
                        <p class="text-center">
                            Hora con más accesos
                        </p>
                        <p class="f-600 text-center">
                            {{ maxHoras.leecheros.alias }}
                        </p>
                        <p class="text-center">
                            {{ agregarSeparadoresNumero(maxHoras.leecheros.leecheros) }}
                        </p>
                    </div>
                    <div class="bg-light-f5 shadow br-10 text-general px-3 py-2 my-2">
                        <p class="text-center">
                            Hora con más interacciones
                        </p>
                        <p class="f-600 text-center">
                            {{ maxHoras.interacciones.alias }}
                        </p>
                        <p class="text-center">
                            {{ agregarSeparadoresNumero(maxHoras.interacciones.interacciones) }}
                        </p>
                    </div>
                </div>
                <div class="col">
                    <echart :options="chart_promedio" />
                    <div v-if="_.sumBy(chart_promedio.dataset.source,'leecheros') == 0 && _.sumBy(chart_promedio.dataset.source,'interacciones') == 0" class="position-absolute h-100 w-100" style="top:0px;left:0px;opacity:0.8;filter:blur(6px);background-color:#ffffff;z-index:1;" />
                    <div v-if="_.sumBy(chart_promedio.dataset.source,'leecheros') == 0 && _.sumBy(chart_promedio.dataset.source,'interacciones') == 0" class="img-sindatos text-center">
                        <img class="obj-cover" src="/img/otros/no_datos.svg" />
                    </div>
                </div>
            </div>
            <div class="row mx-0 justify-center my-5">
                <div class="col-12 my-3">
                    <p class="text-general text-center f-17 f-500">
                        {{ $config.vendedor }} con mayor interacción respecto a las ventas generadas
                    </p>
                </div>
                <div class="col-auto d-middle text-general2">
                    <div class="bg-general3 mx-1" style="width:18px;height:18px;" />
                    A través de la web
                </div>
                <div class="col-auto d-middle text-general2">
                    <div class="bg-general2 mx-1" style="width:18px;height:18px;" />
                    A través de Android
                </div>
                <div class="col-auto d-middle text-general2">
                    <div class="bg-light-f5 mx-1" style="width:18px;height:18px;" />
                    A través de iOs
                </div>
                <div class="col-12 px-0 mt-3">
                    <div class="row mx-0 px-3 justify-content-center">
                        <div v-for="(data, d) in interacciones" :key="d" class="text-center mx-2" style="width:116px;">
                            <el-tooltip placement="bottom" effect="light" :content="data.user.nombre">
                                <img :src="data.user.foto" width="116" height="116" class="obj-cover border br-10" alt="" />
                            </el-tooltip>
                            <p class="text-center f-600 text-general my-2">
                                {{ agregarSeparadoresNumero(data.interacciones) }}
                            </p>
                            <div class="mx-0 d-flex br-5" :class="data.porcentaje_android == 0 && data.porcentaje_ios == 0 && data.porcentaje_web == 0 ? 'border' : ''" style="height:20px;">
                                <div class="bg-general3 h-100" :class="data.porcentaje_android == 0 && data.porcentaje_ios == 0 ? 'br-5' : 'br-l-5'" :style="`width:${porcentajesBordes(data.porcentaje_web)}%;`" />
                                <div class="bg-general2 h-100" :class="data.porcentaje_web == 0 && data.porcentaje_ios == 0 ? 'br-5' : data.porcentaje_web == 0 ? 'br-l-5' : 'br-r-5'" :style="`width:${porcentajesBordes(data.porcentaje_android)}%;`" />
                                <div class="bg-light-f5 h-100" :class="data.porcentaje_web == 0 && data.porcentaje_android == 0 ? 'br-5' : 'br-r-5'" :style="`width:${porcentajesBordes(data.porcentaje_ios)}%;`" />
                            </div>
                            <p class="text-general text-center my-2 f-14">
                                {{ separadorNumero(data.ventas) }}
                            </p>
                            <p class="text-general text-center my-2 f-14">
                                <i> {{ data.cedis }} </i>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-0 justify-center my-5">
                <div class="col-12 my-3">
                    <p class="text-general text-center f-17 f-500">
                        {{ $config.vendedor }} con mayores ventas respecto a las interacciones
                    </p>
                </div>
                <div class="col-auto d-middle text-general2">
                    <div class="bg-general3 mx-1" style="width:18px;height:18px;" />
                    A través de la web
                </div>
                <div class="col-auto d-middle text-general2">
                    <div class="bg-general2 mx-1" style="width:18px;height:18px;" />
                    A través de Android
                </div>
                <div class="col-auto d-middle text-general2">
                    <div class="bg-light-f5 mx-1" style="width:18px;height:18px;" />
                    A través de iOs
                </div>
                <div class="col-12 px-0 mt-3">
                    <div class="row mx-0 px-3 justify-content-center">
                        <div v-for="(data, d) in ventas" :key="d" class="text-center mx-2" style="width:116px;">
                            <el-tooltip placement="bottom" effect="light" :content="data.user.nombre">
                                <img :src="data.user.foto" width="116" height="116" class="obj-cover border br-10" alt="" />
                            </el-tooltip>
                            <p class="text-center f-600 text-general my-2">
                                {{ separadorNumero(data.ventas) }}
                            </p>
                            <div class="mx-0 d-flex br-5" :class="data.porcentaje_android == 0 && data.porcentaje_ios == 0 && data.porcentaje_web == 0 ? 'border' : ''" style="height:20px;">
                                <div class="bg-general3 h-100" :class="data.porcentaje_android == 0 && data.porcentaje_ios == 0 ? 'br-5' : 'br-l-5'" :style="`width:${porcentajesBordes(data.porcentaje_web)}%;`" />
                                <div class="bg-general2 h-100" :class="data.porcentaje_web == 0 && data.porcentaje_ios == 0 ? 'br-5' : data.porcentaje_web == 0 ? 'br-l-5' : 'br-r-5'" :style="`width:${porcentajesBordes(data.porcentaje_android)}%;`" />
                                <div class="bg-light-f5 h-100" :class="data.porcentaje_web == 0 && data.porcentaje_android == 0 ? 'br-5' : 'br-r-5'" :style="`width:${porcentajesBordes(data.porcentaje_ios)}%;`" />
                            </div>
                            <p class="text-general text-center my-2 f-14">
                                {{ agregarSeparadoresNumero(data.interacciones) }}
                            </p>
                            <p class="text-general text-center my-2 f-14">
                                <i> {{ data.cedis }} </i>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-0 justify-center mb-4">
                <div class="col-5">
                    <p class="text-center f-500 text-general mb-3 f-18">
                        Interacciones de todos los {{ $config.vendedor + 's' }} a través de todas las plataformas
                    </p>
                    <echart :options="interaccionesWebMovil" />
                    <div class="row mx-0 justify-center">
                        <div class="d-middle bg-light-f5 shadow py-2 br-10 my-3 cr-pointer" style="max-width:450px;" @click="primeraVez(1, 'Vendedores que ingresaron por primera vez al sistema')">
                            <div class="col-auto">
                                <img :src="url_primera" alt="" />
                            </div>
                            <div class="col text-general f-17">
                                {{ $config.vendedor + 's' }} que ingresaron por primera vez al sistema
                            </div>
                            <div class="col-auto text-general d-middle-center f-20 f-600">
                                {{ agregarSeparadoresNumero(primerosAccesos.length) }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-5">
                    <p class="text-center f-500 text-general mb-3 f-18">
                        {{ $config.vendedor + 's' }} únicos que ingresaron a través de todas las plataformas
                    </p>
                    <echart :options="leecherosWebMovil" />
                    <div class="row mx-0 justify-center">
                        <div class="d-middle bg-light-f5 shadow py-2 br-10 my-3 cr-pointer" style="max-width:450px;" @click="primeraVez(2, 'Vendedores que realizaron su primera compra')">
                            <div class="col-auto">
                                <img :src="url_productos" alt="" />
                            </div>
                            <div class="col text-general f-17">
                                {{ $config.vendedor }} que realizaron su primera compra
                            </div>
                            <div class="col-auto text-general d-middle-center f-20 f-600">
                                {{ agregarSeparadoresNumero(primerosPedidos.length) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-compra-ingreso ref="modalCompraIngreso" :titulo="tituloModal" />
    </section>
</template>

<script>

import Vue from 'vue'
import echarts from 'echarts'
import { mapGetters } from 'vuex'
import moment from 'moment'
import Accesos from "~/services/accesos_estadisticas"
Vue.component('echarts',echarts);
let colorGeneral = getComputedStyle(document.documentElement).getPropertyValue('--color-general')
let colorGeneral2 = getComputedStyle(document.documentElement).getPropertyValue('--color-db')

export default {
    components: {
        modalCompraIngreso: () => import ('./partials/modalCompraIngreso')
    },
    data(){
        let self = this
        return{
            leecherosSelect: [],
            tipoDias: 1,
            tipoMeses: 1,
            id_leechero: null,
            value1: '',
            url_primera: '/img/ilustraciones/ingresos.svg',
            url_productos: '/img/ilustraciones/Productos.svg',
            tituloModal: '',
            url_image: 'https://img.freepik.com/foto-gratis/joven-confiado_1098-20868.jpg?size=626&ext=jpg',
            maxHoras: {
                leecheros:{
                    alias: '',
                    leecheros: 0,
                    interacciones: 0,
                },
                interacciones:{
                    alias: '',
                    leecheros: 0,
                    interacciones: 0,
                },
            },
            chart_promedio: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    backgroundColor: '#F5F5F5',
                    borderColor: '#F5F5F5',
                    formatter: function(obj){
                        // var value = obj.value;
                        return '<div style="border-bottom: 1px solid rgba(0,0,0,.3); font-size: 18px;padding-bottom: 7px;margin-bottom: 7px">'
                        + `<p class="text-general2 text-center" >${obj[0].data.alias}</p>`
                        + '</div>'
                        + '<div class="" style="min-width:150px" >'
                        +  `<i class="icon-blank f-18" style="color:${obj[0].color}" /><span class="text-general2 f-14">${self.agregarSeparadoresNumero(obj[0].data.leecheros)} ${obj[0].seriesName} </span>` + '<br>'
                        +  `<i class="icon-brightness f-18" style="color:${obj[1].color}" /><span class="text-general2 f-14">${self.agregarSeparadoresNumero(obj[1].data.interacciones)} ${obj[1].seriesName} </span>` + '<br>'
                        + '</div>'
                    }
                },
                legend: {
                    data: ['Vendedores que ingresaron', 'Interacciones totales']
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                    },
                    {
                        type: 'category',
                        boundaryGap: true,
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Valor',
                    },
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Pedidos',
                    }
                ],
                dataset:{
                    dimensions:['alias', 'leecheros','interacciones'],
                    source: []
                },
                series: [
                    {
                        name: 'Vendedores que ingresaron',
                        type: 'bar',
                        // xAxisIndex: 1,
                        yAxisIndex: 1,
                        itemStyle: {
                            color: colorGeneral2
                        },
                    },
                    {
                        name: 'Interacciones totales',
                        type: 'line',
                        symbol:'circle',
                        symbolSize: 10,
                        itemStyle: {
                            color: colorGeneral
                        },
                    }
                ]
            },
            accesoLeecherosDias: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    backgroundColor: '#F5F5F5',
                    borderColor: '#F5F5F5',
                    formatter: function(obj){
                        // var value = obj.value;
                        return '<div style="border-bottom: 1px solid rgba(0,0,0,.3); font-size: 18px;padding-bottom: 7px;margin-bottom: 7px">'
                        + `<p class="text-general2 text-center" >${obj[0].data.fecha}</p>`
                        + '</div>'
                        + '<div class="" style="min-width:150px" >'
                        +  `<i class="icon-blank f-18" style="color:${obj[0].color}" /><span class="text-general2 f-14">${self.agregarSeparadoresNumero(obj[0].data.leecheros)} ${obj[0].seriesName} </span>` + '<br>'
                        +  `<i class="icon-brightness f-18" style="color:${obj[1].color}" /><span class="text-general2 f-14">${self.agregarSeparadoresNumero(obj[1].data.interacciones)} ${obj[1].seriesName} </span>` + '<br>'
                        + '</div>'
                    }
                },
                legend: {
                    data: ['Vendedores que ingresaron', 'Interacciones totales']
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                        show:false
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Valor',
                    },
                    {
                        type: 'value',
                        scale: true,
                        show: false
                        // name: 'Pedidos',
                    }
                ],
                dataset:{
                    dimensions:['fecha', 'leecheros','interacciones'],
                    source: []
                },
                series: [
                    {
                        name: 'Vendedores que ingresaron',
                        type: 'bar',
                        barMaxWidth: 30,
                        // data: [120, 132, 101, 134, 90, 230, 210],
                        itemStyle: {
                            normal: {
                                color: '#DBDBDB',
                            },
                        },
                    },
                    {
                        name: 'Interacciones totales',
                        // data: [220, 453, 301, 354, 290, 330, 320],
                        symbol: 'circle',
                        yAxisIndex: 1,
                        symbolSize: 10,
                        type: 'line',
                        itemStyle: {
                            normal: {
                                color: colorGeneral,
                            },
                        },
                    },
                ]
            },
            accesoLeecherosMeses: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    backgroundColor: '#F5F5F5',
                    borderColor: '#F5F5F5',
                    formatter: function(obj){
                        // var value = obj.value;
                        return '<div style="border-bottom: 1px solid rgba(0,0,0,.3); font-size: 18px;padding-bottom: 7px;margin-bottom: 7px">'
                        + `<p class="text-general2 text-center" >${obj[0].data.mes}</p>`
                        + '</div>'
                        + '<div class="" style="min-width:150px" >'
                        +  `<i class="icon-blank f-18" style="color:${obj[0].color}" /><span class="text-general2 f-14">${self.agregarSeparadoresNumero(obj[0].data.leecheros)} ${obj[0].seriesName} </span>` + '<br>'
                        +  `<i class="icon-brightness f-18" style="color:${obj[1].color}" /><span class="text-general2 f-14">${self.agregarSeparadoresNumero(obj[1].data.interacciones)} ${obj[1].seriesName} </span>` + '<br>'
                        + '</div>'
                    }
                },
                legend: {
                    data: ['Vendedores que ingresaron', 'Interacciones totales']
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                        show:false
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Valor',
                    },
                    {
                        type: 'value',
                        scale: true,
                        show: false
                        // name: 'Pedidos',
                    }
                ],
                dataset:{
                    dimensions:['mes', 'leecheros','interacciones'],
                    source: []
                },
                series: [
                    {
                        name: 'Vendedores que ingresaron',
                        type: 'bar',
                        barMaxWidth: 30,
                        // data: [120, 132, 101, 134, 90, 230, 210],
                        itemStyle: {
                            normal: {
                                color: '#DBDBDB',
                            },
                        },
                    },
                    {
                        name: 'Interacciones totales',
                        // data: [220, 453, 301, 354, 290, 330, 320],
                        symbol: 'circle',
                        yAxisIndex: 1,
                        symbolSize: 10,
                        type: 'line',
                        itemStyle: {
                            normal: {
                                color: colorGeneral,
                            },
                        },
                    },
                ]
            },
            interaccionesWebMovil: {
                color: [
                    '#000000',
                    '#DBDBDB',
                    '#707070',
                ],
                tooltip: {
                    trigger: 'item',
                    formatter: function(params){
                        return `${params.value.nombre}: ${self.agregarSeparadoresNumero(params.value.cantidad)} (${params.percent}%)`
                    }
                },
                dataset:{
                    dimensions:['nombre','cantidad'],
                    source:[],
                },
                series: [
                    {
                        name: 'Motivos',
                        type: 'pie',
                        center: ['50%', '50%'],
                        radius: ['50%', '70%'],
                        label:{
                            show: false,
                            color: '#707070'
                        },
                        labelLine:{
                            lineStyle:{
                                color: '#707070'
                            }
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            },
            leecherosWebMovil: {
                color: [
                    '#000000',
                    '#DBDBDB',
                    '#707070',
                ],
                tooltip: {
                    trigger: 'item',
                    formatter: function(params){
                        return `${params.value.nombre}: ${self.agregarSeparadoresNumero(params.value.cantidad)} (${params.percent}%)`
                    }
                },
                dataset:{
                    dimensions:['nombre','cantidad'],
                    source:[],
                },
                series: [
                    {
                        name: 'Motivos',
                        type: 'pie',
                        center: ['50%', '50%'],
                        radius: ['50%', '70%'],
                        label:{
                            show: false,
                            color: '#707070'
                        },
                        labelLine:{
                            lineStyle:{
                                color: '#707070'
                            }
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            },
            rangos: [
                { id: 1, nombre: "Hoy"},
                { id: 2, nombre: "Ayer"},
                { id: 3, nombre: "Última semana"},
                { id: 4, nombre: "Semana anterior"},
                { id: 5, nombre: "Último mes"},
                { id: 6, nombre: "Mes anterior"},
                { id: 7, nombre: "Último año"},
                { id: 8, nombre: "Personalizado"},
            ],
            rango: 5,
            fecha: [moment().subtract(1, 'month').toDate(),new Date()],
            disable_fecha: true,
            graficasRangos: true,
            cargandoInforme: true,
            maxMesesWeb: {},
            maxMesesAndroid: {},
            maxMesesIos: {},
            maxMeses: {
                leecheros:{
                    mes: '',
                    fecha: '',
                    leecheros: 0,
                    interacciones: 0,
                },
                interacciones:{
                    mes: '',
                    fecha: '',
                    leecheros: 0,
                    interacciones: 0,
                },
            },
            mesesWeb: [],
            mesesAndroid: [],
            mesesIos: [],
            diasWeb: [],
            diasAndroid: [],
            diasIos: [],
            primerosAccesos: [],
            primerosPedidos: [],
            interacciones: [],
            ventas: [],
            plataformasInteracciones: [],
            plataformasLeecheros: [],
            diasTotalesWeb: {
                leecheros: 0,
                interacciones: 0,
            },
            diasTotalesAndroid: {
                leecheros: 0,
                interacciones: 0,
            },
            diasTotalesIos: {
                leecheros: 0,
                interacciones: 0,
            },
            diasTotales: {
                leecheros: 0,
                interacciones: 0,
            },
        }
    },
    computed: {
        ...mapGetters({
            id_moneda:'cedis/id_moneda',
            id_cedis:'cedis/id_cedis',
        }),
        cargandoTerminado(){
            return  !this.graficasRangos &&
                    !this.cargandoInforme
        }
    },
    watch: {
        rango(val){
            switch (val){
            case 1:
                this.fecha = []
                this.fecha[0] = new Date()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 2:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'day').toDate()
                this.fecha[1] = moment().subtract(1, 'day').toDate()
                this.disable_fecha = true
                break;
            case 3:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 4:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').startOf('week').toDate()
                this.fecha[1] = moment().subtract(1, 'week').endOf('week').toDate()
                this.disable_fecha = true
                break;
            case 5:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 6:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').startOf('month').toDate()
                this.fecha[1] = moment().subtract(1, 'month').endOf('month').toDate()
                this.disable_fecha = true
                break;
            case 7:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'year').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 8:
                this.fecha = []
                this.fecha[0] = moment().startOf('month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = false
                break;

            default:
                break;
            }
        },
        id_moneda(val,oldVal){
            if(oldVal == null) return
            this.cargando()
            this.montar()
        },
        id_cedis(val,oldVal){
            if(oldVal == null) return
            this.remoteMethod('')
            this.cargando()
            this.montar()
        },
        tipoDias(){
            this.cambioTipoDia()
        },
        tipoMeses(){
            this.cambioTipoMes()
        }
    },
    mounted(){
        this.montar()
    },
    methods: {
        cargando(informes = true){
            if (informes){
                this.graficasRangos = true

            }
            this.cargandoInforme = true
            this.cargandoCriterios = true
        },
        async montar(){
            try {
                if(this.id_moneda === null && this.id_cedis == null){
                    setTimeout(async() => {
                        await this.remoteMethod('')
                        await this.graficasAccesos()
                        await this.informeAccesos()
                    }, 1000);
                }else{
                    await this.remoteMethod('')
                    await this.graficasAccesos()
                    await this.informeAccesos()
                }
            } catch (error){
                console.log(error)
            }
        },
        async remoteMethod(query){
            try {
                let params = {
                    id_cedis: this.id_cedis,
                    tipo:1,
                    query,
                }
                const { data } = await Accesos.listaLeecheros(params)
                this.leecherosSelect = data
            } catch (e){
                this.options = [];
            }
        },
        async graficasAccesos(){
            try {
                let params = {
                    id_cedis: this.id_cedis,
                    tipo: 1,
                    id_leechero: this.id_leechero,
                }
                this.graficasRangos = true

                const { data } = await Accesos.graficasAccesos(params)

                this.maxMesesWeb = data.maxMesesWeb
                this.maxMesesAndroid = data.maxMesesAndroid
                this.maxMesesIos = data.maxMesesIos
                this.mesesWeb = data.mesesWeb
                this.mesesAndroid = data.mesesAndroid
                this.mesesIos = data.mesesIos
                this.diasWeb = data.diasWeb
                this.diasAndroid = data.diasAndroid
                this.diasIos = data.diasIos
                this.diasTotalesWeb = data.diasTotalesWeb
                this.diasTotalesAndroid = data.diasTotalesAndroid
                this.diasTotalesIos = data.diasTotalesIos

                this.cambioTipoDia()
                this.cambioTipoMes()
            } catch (error){
                console.log(error)
            } finally {
                this.graficasRangos = false
            }
        },
        cambioTipoDia(){
            let tipo = []
            let totales = {
                leecheros: 0,
                interacciones: 0,
            }
            switch (this.tipoDias){
            case 1:
                tipo = this.diasWeb
                totales = this.diasTotalesWeb
                break;
            case 2:
                tipo = this.diasAndroid
                totales = this.diasTotalesAndroid
                break;
            case 3:
                tipo = this.diasIos
                totales = this.diasTotalesIos
                break;
            default:
                tipo = this.diasWeb;
                totales = this.diasTotalesWeb
            }
            this.accesoLeecherosDias.dataset.source = tipo
            this.diasTotales = totales
        },
        cambioTipoMes(){
            let tipo = []
            let max = {
                mes: '',
                fecha: '',
                leecheros: 0,
                interacciones: 0,
            }
            switch (this.tipoMeses){
            case 1:
                tipo = this.mesesWeb
                max = this.maxMesesWeb
                break;
            case 2:
                tipo = this.mesesAndroid
                max = this.maxMesesAndroid
                break;
            case 3:
                tipo = this.mesesIos
                max = this.maxMesesIos
                break;
            default:
                tipo = this.diasWeb;
                max = this.maxMesesWeb;
            }
            this.accesoLeecherosMeses.dataset.source = tipo
            this.maxMeses = max
        },
        async informeAccesos(){
            try {
                let params = {
                    fecha_inicio: moment(this.fecha[0]).format('Y-MM-DD'),
                    fecha_fin: moment(this.fecha[1]).format('Y-MM-DD'),
                    id_moneda: this.id_moneda,
                    id_cedis: this.id_cedis,
                    id_leechero: this.id_leechero,
                    tipo: 1,
                }
                this.cargandoInforme = true

                const {data} = await Accesos.informeAccesos(params)
                this.interacciones = data.interacciones
                this.ventas = data.ventas
                this.interaccionesWebMovil.dataset.source = data.plataformasInteracciones
                this.leecherosWebMovil.dataset.source = data.plataformasLeecheros
                this.primerosAccesos = data.primerosAccesos
                this.primerosPedidos = data.primerosPedidos

                this.chart_promedio.dataset.source = data.horas
                this.maxHoras = data.maxhoras

            } catch (error){
                console.log(error)
            } finally {
                this.cargandoInforme = false
            }
        },
        async informe(){
            try {
                this.cargando(false)
                await this.informeAccesos()
            } catch (error){
                console.log(error)
            }
        },
        primeraVez(tipo, titulo){
            let ids = tipo == 1 ? this.primerosAccesos : this.primerosPedidos
            this.tituloModal = titulo;
            let fecha_inicio =  moment(this.fecha[0]).format('Y-MM-DD')
            let fecha_fin =  moment(this.fecha[1]).format('Y-MM-DD')
            let fecha = `${this.formatearFecha(fecha_inicio,'D MMM YYYY')} - ${this.formatearFecha(fecha_fin,'D MMM YYYY')}`
            this.$refs.modalCompraIngreso.toggle(ids, this.id_moneda, 1, fecha);
        }
    }
}
</script>
<style lang="scss" scoped>
.filter-button{
    cursor: pointer;
    width: 35px;
    height: 35px;
}
.img-sindatos{
        position: absolute;
        z-index: 3;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0%;
        left: 0px;
        width: 100%;
        height: 100%;
        img{
            // border-radius: 50%;
            /* max-height: 100%; */
            box-shadow: 0px 3px 6px #00000029;
            max-width: 50%;
            height: 50%;
        }
        .mensaje{
            color: #fff;
            text-shadow: 0 2px 3px #000000;
            position: absolute;
            top: 58%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
</style>
