<template>
    <section class="position-relative">
        <cargando v-if="cargando" />
        <div class="row mx-0 align-items-center mb-3">
            <i class="icon-back f-20 text-general cr-pointer" @click="irAtras" />
            <div class="col-auto px-2 f-17 text-general">
                Perfil del empacador
            </div>
            <el-tooltip placement="bottom" content="Eliminar" effect="light">
                <div class="btn-action border ml-auto" @click="confirmarEliminar">
                    <i class="icon-delete-trash f-18" />
                </div>
            </el-tooltip>
            <el-tooltip placement="bottom" content="Editar" effect="light">
                <div class="btn-action border ml-2" @click="irEditar">
                    <i class="icon-pencil-outline f-18" />
                </div>
            </el-tooltip>
        </div>
        <div class="row mx-0">
            <div class="col-4 px-4 py-3 bg-white br-8">
                <div class="row mx-0 align-items-center mb-3">
                    <img :src="empacador.foto" width="90" height="90" class="obj-cover br-6" />
                    <div class="col">
                        <p class="text-general f-20">
                            {{ empacador.nombre }}
                        </p>
                    </div>
                </div>
                <div class="row mx-0 pl-4">
                    <i class="icon-phone f-17 text-general" />
                    <div class="col text-general f-16">
                        {{ empacador.telefono }}
                    </div>
                </div>
                <div class="row mx-0 pl-4 my-2">
                    <i class="icon-mail f-17 text-general" />
                    <div class="col text-general f-16">
                        {{ empacador.correo }}
                    </div>
                </div>
                <div class="row mx-0 pl-4 my-2">
                    <i class="icon-mail f-17 text-general" />
                    <div class="col text-general f-16">
                        CEDIS: <br />
                        <span v-for="(cedi,index) in cedis" :key="index">
                            {{ cedi.nombre }}<span v-if="index+1 < cedis.length">,</span><span v-if="index+1 == cedis.length">.</span>
                        </span>
                    </div>
                </div>
                <div class="row mx-0 mt-4 pt-2">
                    <div class="col mr-2 bg-fondo px-0 border br-8">
                        <div class="row mx-0 justify-center align-items-center br-t-8" style="height:39px;background-color:#DFE4E8;">
                            <i class="icon-cart-arrow-down f-18" />
                            Alistados
                        </div>
                        <div class="row mx-0 justify-center text-general f-600 f-30">
                            {{ empacador.total_alistados }}
                        </div>
                        <div class="row mx-0 justify-center f-14 text-green my-1">
                            <!--+ 99%-->
                        </div>
                        <div class="row mx-0 justify-center f-14 text-general2 mb-2">
                            Últimos 30 días
                        </div>
                    </div>
                    <div class="col ml-2 bg-fondo px-0 border br-8">
                        <div class="row mx-0 justify-center align-items-center br-t-8 text-white" style="height:39px;background-color:#637381;">
                            <i class="icon-packed f-18" />
                            Empacados
                        </div>
                        <div class="row mx-0 justify-center text-general f-600 f-30">
                            {{ empacador.total_empacados }}
                        </div>
                        <div class="row mx-0 justify-center f-14 text-green my-1">
                            <!--+ 99%-->
                        </div>
                        <div class="row mx-0 justify-center f-14 text-general2 mb-2">
                            Últimos 30 días
                        </div>
                    </div>
                </div>
            </div>
            <div class="col ml-3 px-3 py-2 bg-white br-8">
                <p class="text-general f-18 f-500 mt-2 mb-3">
                    Pedidos alistados y empacados de las ultimas 24 semanas
                </p>
                <echart :options="pedidos_alistados" />
            </div>
        </div>
        <!-- Partials -->
        <modal-eliminar ref="modalEliminarEmpacador" titulo="Eliminar empacador" mensaje="¿Desea eliminar este empacador?" @eliminar="eliminarEmpacador" />
        <modal-editar-empacador ref="modalEditarEmpacador" @actualizar="getEmpacador" />
    </section>
</template>

<script>
import Vue from 'vue'
import echarts from 'echarts'
Vue.component('echarts',echarts);
import Service from '~/services/empacadores/empacadores'
import moment from 'moment'
export default {
    components: {
        modalEditarEmpacador: () => import('./partials/modalEditarEmpacador')
    },
    data(){
        return {
            cargando: false,
            empacador: {},
            cedis: [],
            grafica: [],
            pedidos_alistados: {
                legend: {
                    data: ['Alistados', 'Empacados']
                },
                tooltip: {
                    trigger: 'axis',
                    formatter: (params) => {
                        let barAlistados = params[0]
                        let barEmpacados = params[1]
                        if(barAlistados && barEmpacados){
                            return `${barAlistados.axisValue} <br />
                            ${barAlistados.marker} ${barAlistados.value} Alistados <br />
                            ${barEmpacados.marker} ${barEmpacados.value} Empacados
                            `
                        }
                        if(barAlistados.seriesName === 'Alistados'){
                            return `${barAlistados.axisValue} <br />
                            ${barAlistados.marker} ${barAlistados.value} Alistados <br />
                            `
                        }
                        if(barAlistados.seriesName === 'Empacados'){
                            return `${barAlistados.axisValue} <br />
                            ${barAlistados.marker} ${barAlistados.value} Empacados <br />
                            `
                        }
                    },
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                xAxis: {
                    type: 'category',
                    show: false,
                    data: [],
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "#EFEFEF"
                        }
                    },
                },
                yAxis: [
                    {
                        type: 'value',
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#EFEFEF"
                            }
                        },
                        axisLabel: {
                            color: '#6D6B6B'
                        }
                    }
                ],
                series: [
                    {
                        data: [],
                        name: 'Alistados',
                        stack: 'one',
                        itemStyle: {
                            color: '#DFE4E8',
                        },
                        barWidth: 20,
                        type: 'bar',
                        emphasis: {
                            focus: 'series'
                        },
                    },
                    {
                        data: [],
                        name: 'Empacados',
                        stack: 'one',
                        itemStyle: {
                            color: '#637381',
                        },
                        barWidth: 20,
                        type: 'bar',
                        emphasis: {
                            focus: 'series'
                        },
                    }
                ]
            }
        }
    },
    computed:{
        id_picking(){
            return this.$route.params.id_picking
        },
    },
    mounted(){
        this.getEmpacador();
    },
    methods: {
        async getEmpacador(){
            try {
                this.cargando = true;
                const {data} = await Service.getEmpacador(this.id_picking)
                this.empacador = data.empacador;
                this.cedis = data.cedis;
                this.grafica = data.grafica;
                this.armarGrafica();
            } catch (e){
                this.error_catch(e)
            } finally{
                this.cargando = false
            }
        },
        confirmarEliminar(){
            this.$refs.modalEliminarEmpacador.toggle();
        },
        async eliminarEmpacador(){
            try {
                const {data} = await Service.deleteEmpacador(this.id_picking)
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.$refs.modalEliminarEmpacador.toggle();
                this.irAtras();
            } catch (e){
                this.error_catch(e)
            }
        },
        armarGrafica(){
            let fechas = [];
            let alistados = [];
            let empacados = [];
            for (let i = 0; i < this.grafica.length; i++){
                let inicial = moment(this.grafica[i].fecha_inicial).format("DD MMM YYYY");
                let final = moment(this.grafica[i].fecha_final).format("DD MMM YYYY");
                fechas.push(inicial+" - "+final);
                alistados.push(this.grafica[i].total_alistados);
                empacados.push(this.grafica[i].total_empacados);
            }
            this.pedidos_alistados.xAxis.data = fechas;
            this.pedidos_alistados.series[0].data = alistados;
            this.pedidos_alistados.series[1].data = empacados;
        },
        irAtras(){
            this.$router.push({name:'admin.picking'});
        },
        irEditar(){
            this.$refs.modalEditarEmpacador.toggle();
        },
    }
}
</script>