<template>
    <section class="mx-3 mt-2 border">
        <cargando v-if="loading" />
        <div class="row align-items-center mx-0 py-2 border-bottom">
            <div class="br-50 ml-3 text-white bg-general3 d-middle-center cr-pointer" style="width:25px;height:25px;" @click="goBack()">
                <i class="icon-left f-20" />
            </div>
            <div class="col-auto text-general f-15">
                Buscar  {{ campana.tipo == 1 ? 'clientes' : 'Vendedores' }} por criterios
            </div>
            <div class="col-auto ml-auto text-general f-15">
                Fecha de filtro
            </div>
            <div class="col-3">
                <el-select v-model="valueFiltro" placeholder="Select" class="w-100" size="small" @change="listarClientes()">
                    <el-option
                    v-for="item in rangoFechas"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    />
                </el-select>
            </div>
            <div class="col-auto">
                <div class="bg-general text-white px-3 py-1 br-10 cr-pointer" @click="handleAnadir()">
                    Añadir selección
                </div>
            </div>
        </div>
        <div class="row mx-0">
            <div class="col-12">
                <tabla-general
                :data="tablaClientes"
                alto="calc(100vh - 237px)"
                :mostrar-buscador="false"
                activar-seleccion-multiple
                :usar-paginacion="false"
                @seleccion="selccionarClientes"
                >
                    <template slot="adicionales-izquierda">
                        <el-table-column fixed label="Cliente" class-name="text-center text-general2 f-16" width="250">
                            <template slot="header" slot-scope="scope">
                                <p class="f-17 text-general">
                                    {{ campana.tipo == 1 ?'Cliente' : 'Vendedor' }}
                                </p>
                                <el-input v-model="cliente_filter" class="w-100" />
                            </template>
                            <template slot-scope="{row}">
                                {{ row.nombre }}
                            </template>
                        </el-table-column>
                        <el-table-column class-name="text-center text-general2 f-16" width="180">
                            <template slot="header" slot-scope="scope">
                                <p class="f-17 text-general">
                                    Ciudad
                                </p>
                                <el-input v-model="ciudad_filter" class="w-100" />
                            </template>
                            <template slot-scope="{row}">
                                {{ row.ciudad }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Genero" class-name="text-center text-general2 f-16" width="180">
                            <template slot="header" slot-scope="scope">
                                <p class="f-17 text-general">
                                    Género
                                </p>
                                <el-input v-model="genero_filter" class="w-100" />
                            </template>
                            <template slot-scope="{row}">
                                {{ row.genero }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Edad" class-name="text-center text-general2 f-16" width="200">
                            <template slot="header" slot-scope="scope">
                                <div class="d-flex">
                                    <div class="col px-0">
                                        <p class="f-17 text-general">
                                            Edad
                                        </p>
                                        <div class="border br-10 py-2 d-flex justify-content-between bg-whitesmoke2">
                                            <span class="ml-2">
                                                {{ edad_filter }}
                                            </span>
                                            <i class="icon-cancel text-general-red cr-pointer" @click.stop="edad_filter = null, accionFiltro();removerRango('edad')" />
                                        </div>
                                    </div>
                                    <div class="col-auto px-0 pt-4">
                                        <el-popover
                                        placement="bottom"
                                        width="300"
                                        trigger="click"
                                        >
                                            <p class="text-general text-center my-2 f-15 f-600">
                                                Rango
                                            </p>
                                            <div class="row mx-0">
                                                <div class="col">
                                                    <el-input v-model="minEdad" placeholder="De" />
                                                </div>
                                                <div class="col">
                                                    <el-input v-model="maxEdad" placeholder="Hasta" />
                                                </div>
                                            </div>
                                            <div class="row mx-0 justify-content-center py-2">
                                                <div class="bg-general text-white f-17 px-4 py-1 br-10 cr-pointer" @click="accionFiltro('edad');filtrarRango('edad', 'minEdad', 'maxEdad')">
                                                    Filtrar
                                                </div>
                                            </div>
                                            <i slot="reference" class="mt-2 icon-arrow-collapse-horizontal text-general2 f-25 cr-pointer" />
                                        </el-popover>
                                    </div>
                                </div>
                            </template>
                            <template slot-scope="{row}">
                                {{ row.edad }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Compras" class-name="text-center text-general2 f-16" width="200">
                            <template slot="header" slot-scope="scope">
                                <div class="d-flex">
                                    <div class="col px-0">
                                        <p class="f-17 text-general">
                                            Compras <i class="icon-calendar-empty f-12 text-general-red" />
                                        </p>
                                        <div class="border br-10 py-2 d-flex justify-content-between bg-whitesmoke2">
                                            <span class="ml-2">
                                                {{ cant_compras_filter }}
                                            </span>
                                            <i class="icon-cancel text-general-red cr-pointer" @click.stop="cant_compras_filter = null, accionFiltro();removerRango('cant_compras')" />
                                        </div>
                                    </div>
                                    <div class="col-auto px-0 pt-4">
                                        <el-popover placement="bottom" width="300" trigger="click">
                                            <p class="text-general text-center my-2 f-15 f-600">
                                                Rango
                                            </p>
                                            <div class="row mx-0">
                                                <div class="col">
                                                    <el-input v-model="minCantCompras" placeholder="De" />
                                                </div>
                                                <div class="col">
                                                    <el-input v-model="maxCantCompras" placeholder="Hasta" />
                                                </div>
                                            </div>
                                            <div class="row mx-0 justify-content-center py-2">
                                                <div class="bg-general text-white f-17 px-4 py-1 br-10 cr-pointer" @click="accionFiltro('cant_compras');filtrarRango('cant_compras', 'minCantCompras', 'maxCantCompras')">
                                                    Filtrar
                                                </div>
                                            </div>
                                            <i slot="reference" class="mt-2 icon-arrow-collapse-horizontal text-general2 f-25 cr-pointer" />
                                        </el-popover>
                                    </div>
                                </div>
                            </template>
                            <template slot-scope="{row}">
                                {{ row.cant_compras }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Valor última compra" class-name="text-center text-general2 f-16" width="250">
                            <template slot="header" slot-scope="scope">
                                <div class="d-flex">
                                    <div class="col px-0">
                                        <p class="f-17 text-general">
                                            Valor última compra
                                        </p>
                                        <div class="border br-10 py-2 d-flex justify-content-between bg-whitesmoke2">
                                            <span class="ml-2">
                                                {{ valor_compra_filter }}
                                            </span>
                                            <i class="icon-cancel text-general-red cr-pointer" @click.stop="valor_compra_filter = null, accionFiltro();removerRango('v_ul_compra')" />
                                        </div>
                                    </div>
                                    <div class="col-auto pt-4 px-0">
                                        <el-popover placement="bottom" width="300" trigger="click">
                                            <p class="text-general text-center my-2 f-15 f-600">
                                                Rango
                                            </p>
                                            <div class="row mx-0">
                                                <div class="col">
                                                    <el-input v-model="minValorCompras" placeholder="De" />
                                                </div>
                                                <div class="col">
                                                    <el-input v-model="maxValorCompras" placeholder="Hasta" />
                                                </div>
                                            </div>
                                            <div class="row mx-0 justify-content-center py-2">
                                                <div class="bg-general text-white f-17 px-4 py-1 br-10 cr-pointer" @click="accionFiltro('valor_ultima_compra'); filtrarRango('v_ul_compra', 'minValorCompras' , 'maxValorCompras')">
                                                    Filtrar
                                                </div>
                                            </div>
                                            <i slot="reference" class="mt-2 icon-arrow-collapse-horizontal text-general2 f-25 cr-pointer" />
                                        </el-popover>
                                    </div>
                                </div>
                            </template>
                            <template slot-scope="{row}">
                                {{ convertMoney(row.valor_ultima_compra, $usuario.idm_moneda) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Fecha última compra" class-name="text-center text-general2 f-16" width="250">
                            <template slot="header" slot-scope="scope">
                                <div class="d-flex">
                                    <div class="col px-0">
                                        <p class="f-17 text-general">
                                            Fecha última compra
                                        </p>
                                        <div class="border br-10 py-2 d-flex justify-content-between bg-whitesmoke2">
                                            <span class="ml-2">
                                                {{ fecha_compra_filter }}
                                            </span>
                                            <i class="icon-cancel text-general-red cr-pointer" @click.stop="fecha_compra_filter = null, accionFiltro()" />
                                        </div>
                                    </div>
                                    <div class="col-auto px-0 pt-4">
                                        <el-popover placement="bottom" width="300" trigger="click">
                                            <p class="text-general text-center my-2 f-15 f-600">
                                                Rango
                                            </p>
                                            <div class="row mx-0">
                                                <el-date-picker
                                                v-model="rangoFechaCompra"
                                                type="daterange"
                                                format="yyyy-MM-dd"
                                                value-format="yyyy-MM-dd"
                                                range-separator="-"
                                                start-placeholder="desde"
                                                end-placeholder="hasta"
                                                />
                                            </div>
                                            <div class="row mx-0 justify-content-center py-2">
                                                <div class="bg-general text-white f-17 px-4 py-1 br-10 cr-pointer" @click="accionFiltro('fecha_ultima_compra'), filtrar()">
                                                    Filtrar
                                                </div>
                                            </div>
                                            <i slot="reference" class="mt-2 icon-arrow-collapse-horizontal text-general2 f-25 cr-pointer" />
                                        </el-popover>
                                    </div>
                                </div>
                            </template>
                            <template slot-scope="{row}">
                                {{ row.fecha_ultima_compra || '--' }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Cedis" class-name="text-center text-general2 f-16" width="150">
                            <template slot="header" slot-scope="scope">
                                <p class="f-17 text-general">
                                    cedis
                                </p>
                                <el-input v-model="cedis_filter" />
                            </template>
                            <template slot-scope="{row}">
                                {{ row.cedis }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Vendedor principal" class-name="text-center text-general2 f-16" width="220">
                            <template slot="header" slot-scope="scope">
                                <p class="f-17 text-general">
                                    {{$config.vendedor}} principal
                                </p>
                                <el-input v-model="leechero_filter" class="w-100" />
                            </template>
                            <template slot-scope="{row}">
                                {{ row.lechero }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Antiguedad (Meses)" class-name="text-center text-general2 f-16" width="230">
                            <template slot="header" slot-scope="scope">
                                <div class="d-flex">
                                    <div class="col px-0">
                                        <p class="f-17 text-general">
                                            Antiguedad (Meses)
                                        </p>
                                        <div class="border br-10 py-2 d-flex justify-content-between bg-whitesmoke2">
                                            <span class="ml-2">
                                                {{ antiguedad_filter }}
                                            </span>
                                            <i class="icon-cancel text-general-red cr-pointer" @click.stop="antiguedad_filter = null, accionFiltro();removerRango('antiguedad')" />
                                        </div>
                                    </div>
                                    <div class="col-auto px-0 pt-4">
                                        <el-popover placement="bottom" width="300" trigger="click">
                                            <p class="text-general text-center my-2 f-15 f-600">
                                                Rango
                                            </p>
                                            <div class="row mx-0">
                                                <div class="col">
                                                    <el-input v-model="minAntiguedad" placeholder="De" />
                                                </div>
                                                <div class="col">
                                                    <el-input v-model="maxAntiguedad" placeholder="Hasta" />
                                                </div>
                                            </div>
                                            <div class="row mx-0 justify-content-center py-2">
                                                <div class="bg-general text-white f-17 px-4 py-1 br-10 cr-pointer" @click="accionFiltro('antiguedad');filtrarRango('antiguedad', 'minAntiguedad', 'maxAntiguedad')">
                                                    Filtrar
                                                </div>
                                            </div>
                                            <i slot="reference" class="mt-2 icon-arrow-collapse-horizontal text-general2 f-25 cr-pointer" />
                                        </el-popover>
                                    </div>
                                </div>
                            </template>
                            <template slot-scope="{row}">
                                {{ row.antiguedad }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Calificaciones" class-name="text-center text-general2 f-16" width="200">
                            <template slot="header" slot-scope="scope">
                                <div class="d-flex">
                                    <div class="col px-0">
                                        <p class="f-17 text-general">
                                            Calificaciones <i class="icon-calendar-empty text-general-red f-12" />
                                        </p>
                                        <div class="border br-10 py-2 d-flex justify-content-between bg-whitesmoke2">
                                            <span class="ml-2">
                                                {{ calificaciones_filter }}
                                            </span>
                                            <i class="icon-cancel text-general-red cr-pointer" @click.stop="calificaciones_filter = null, accionFiltro();removerRango('cant_calificaciones')" />
                                        </div>
                                    </div>
                                    <div class="col-auto px-0 pt-4">
                                        <el-popover placement="bottom" width="300" trigger="click">
                                            <p class="text-general text-center my-2 f-15 f-600">
                                                Rango
                                            </p>
                                            <div class="row mx-0">
                                                <div class="col">
                                                    <el-input v-model="minCalificaciones" placeholder="De" />
                                                </div>
                                                <div class="col">
                                                    <el-input v-model="maxCalificaciones" placeholder="Hasta" />
                                                </div>
                                            </div>
                                            <div class="row mx-0 justify-content-center py-2">
                                                <div class="bg-general text-white f-17 px-4 py-1 br-10 cr-pointer" @click="accionFiltro('cant_calificaciones'); filtrarRango('cant_calificaciones', 'minCalificaciones', 'maxCalificaciones')">
                                                    Filtrar
                                                </div>
                                            </div>
                                            <i slot="reference" class="mt-2 icon-arrow-collapse-horizontal text-general2 f-25 cr-pointer" />
                                        </el-popover>
                                    </div>
                                </div>
                            </template>
                            <template slot-scope="{row}">
                                {{ row.cant_calificaciones }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Calificacion promedio" class-name="text-center text-general2 f-16" width="270">
                            <template slot="header" slot-scope="scope">
                                <div class="d-flex">
                                    <div class="col px-0">
                                        <p class="f-17 text-general">
                                            Calificaciones promedio
                                        </p>
                                        <div class="border br-10 py-2 d-flex justify-content-between bg-whitesmoke2">
                                            <span class="ml-2">
                                                {{ calificacion_promedio_filter }}
                                            </span>
                                            <i class="icon-cancel text-general-red cr-pointer" @click.stop="calificacion_promedio_filter = null, accionFiltro();removerRango('calificacion_prod')" />
                                        </div>
                                    </div>
                                    <div class="col-auto px-0 pt-4">
                                        <el-popover placement="bottom" width="300" trigger="click">
                                            <p class="text-general text-center my-2 f-15 f-600">
                                                Rango
                                            </p>
                                            <div class="row mx-0">
                                                <div class="col">
                                                    <el-input v-model="minPromedioCalificaciones" placeholder="De" />
                                                </div>
                                                <div class="col">
                                                    <el-input v-model="maxPromedioCalificaciones" placeholder="Hasta" />
                                                </div>
                                            </div>
                                            <div class="row mx-0 justify-content-center py-2">
                                                <div class="bg-general text-white f-17 px-4 py-1 br-10 cr-pointer" @click="accionFiltro('calificacion_promedio_filter');filtrarRango('calificacion_prod', 'minPromedioCalificaciones', 'maxPromedioCalificaciones')">
                                                    Filtrar
                                                </div>
                                            </div>
                                            <i slot="reference" class="mt-2 icon-arrow-collapse-horizontal text-general2 f-25 cr-pointer" />
                                        </el-popover>
                                    </div>
                                </div>
                            </template>
                            <template slot-scope="{row}">
                                {{ redondearNumero(row.promedio_calificacion,1) || '--' }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Último acceso (Días)" class-name="text-center text-general2 f-16" width="250">
                            <template slot="header" slot-scope="scope">
                                <div class="d-flex">
                                    <div class="col px-0">
                                        <p class="f-17 text-general">
                                            Último acceso (Días)
                                        </p>
                                        <div class="border br-10 py-2 d-flex justify-content-between bg-whitesmoke2">
                                            <span class="ml-2">
                                                {{ ultimo_acceso_filter }}
                                            </span>
                                            <i class="icon-cancel text-general-red cr-pointer" @click.stop="ultimo_acceso_filter = null, accionFiltro();removerRango('dias_u_acc')" />
                                        </div>
                                    </div>
                                    <div class="col-auto px-0 pt-4">
                                        <el-popover placement="bottom" width="300" trigger="click">
                                            <p class="text-general text-center my-2 f-15 f-600">
                                                Rango
                                            </p>
                                            <div class="row mx-0">
                                                <div class="col">
                                                    <el-input v-model="minDiasAcceso" placeholder="De" />
                                                </div>
                                                <div class="col">
                                                    <el-input v-model="maxDiasAcceso" placeholder="Hasta" />
                                                </div>
                                            </div>
                                            <div class="row mx-0 justify-content-center py-2">
                                                <div class="bg-general text-white f-17 px-4 py-1 br-10 cr-pointer" @click="accionFiltro('dias_ult_acceso');filtrarRango('dias_u_acc', 'minDiasAcceso', 'maxDiasAcceso')">
                                                    Filtrar
                                                </div>
                                            </div>
                                            <i slot="reference" class="mt-2 icon-arrow-collapse-horizontal text-general2 f-25 cr-pointer" />
                                        </el-popover>
                                    </div>
                                </div>
                            </template>
                            <template slot-scope="{row}">
                                {{ row.dias_ult_acceso || '--' }}
                            </template>
                        </el-table-column>
                        <el-table-column label="# de accesos" class-name="text-center text-general2 f-16" width="190">
                            <template slot="header" slot-scope="scope">
                                <div class="d-flex">
                                    <div class="col px-0">
                                        <p class="f-17 text-general">
                                            # de accesos <i class="icon-calendar-empty text-general-red f-12" />
                                        </p>
                                        <div class="border br-10 py-2 d-flex justify-content-between bg-whitesmoke2">
                                            <span class="ml-2">
                                                {{ accesos_filter }}
                                            </span>
                                            <i class="icon-cancel text-general-red cr-pointer" @click.stop="accesos_filter = null, accionFiltro();removerRango('cant_accesos')" />
                                        </div>
                                    </div>
                                    <div class="col-auto px-0 pt-4">
                                        <el-popover placement="bottom" width="300" trigger="click">
                                            <p class="text-general text-center my-2 f-15 f-600">
                                                Rango
                                            </p>
                                            <div class="row mx-0">
                                                <div class="col">
                                                    <el-input v-model="minCantAccesos" placeholder="De" />
                                                </div>
                                                <div class="col">
                                                    <el-input v-model="maxCantAccesos" placeholder="Hasta" />
                                                </div>
                                            </div>
                                            <div class="row mx-0 justify-content-center py-2">
                                                <div class="bg-general text-white f-17 px-4 py-1 br-10 cr-pointer" @click="accionFiltro('cant_accesos');filtrarRango('cant_accesos', 'minCantAccesos', 'maxCantAccesos')">
                                                    Filtrar
                                                </div>
                                            </div>
                                            <i slot="reference" class="mt-2 icon-arrow-collapse-horizontal text-general2 f-25 cr-pointer" />
                                        </el-popover>
                                    </div>
                                </div>
                            </template>
                            <template slot-scope="{row}">
                                {{ row.cant_accesos }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Uso de cupones" class-name="text-center text-general2 f-16" width="210">
                            <template slot="header" slot-scope="scope">
                                <div class="d-flex">
                                    <div class="col px-0">
                                        <p class="f-17 text-general">
                                            Uso de cupones <i class="icon-calendar-empty text-general-red f-12" />
                                        </p>
                                        <div class="border br-10 py-2 d-flex justify-content-between bg-whitesmoke2">
                                            <span class="ml-2">
                                                {{ cupones_filter }}
                                            </span>
                                            <i class="icon-cancel text-general-red cr-pointer" @click.stop="cupones_filter = null, accionFiltro();removerRango('cant_cupones')" />
                                        </div>
                                    </div>
                                    <div class="col-auto px-0 pt-4">
                                        <el-popover placement="bottom" width="300" trigger="click">
                                            <p class="text-general text-center my-2 f-15 f-600">
                                                Rango
                                            </p>
                                            <div class="row mx-0">
                                                <div class="col">
                                                    <el-input v-model="minCantCupones" placeholder="De" />
                                                </div>
                                                <div class="col">
                                                    <el-input v-model="maxCantCupones" placeholder="Hasta" />
                                                </div>
                                            </div>
                                            <div class="row mx-0 justify-content-center py-2">
                                                <div class="bg-general text-white f-17 px-4 py-1 br-10 cr-pointer" @click="accionFiltro('cant_cupones');filtrarRango('cant_cupones', 'minCantCupones', 'maxCantCupones')">
                                                    Filtrar
                                                </div>
                                            </div>
                                            <i slot="reference" class="mt-2 icon-arrow-collapse-horizontal text-general2 f-25 cr-pointer" />
                                        </el-popover>
                                    </div>
                                </div>
                            </template>
                            <template slot-scope="{row}">
                                {{ row.cant_cupones }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Uso de bonos" class-name="text-center text-general2 f-16" width="210">
                            <template slot="header" slot-scope="scope">
                                <div class="d-flex">
                                    <div class="col px-0">
                                        <p class="f-17 text-general">
                                            Uso de bonos <i class="icon-calendar-empty text-general-red f-12" />
                                        </p>
                                        <div class="border br-10 py-2 d-flex justify-content-between bg-whitesmoke2">
                                            <span class="ml-2">
                                                {{ bonos_filter }}
                                            </span>
                                            <i class="icon-cancel text-general-red cr-pointer" @click.stop="bonos_filter = null, accionFiltro();removerRango('cant_bonos')" />
                                        </div>
                                    </div>
                                    <div class="col-auto px-0 pt-4">
                                        <el-popover placement="bottom" width="300" trigger="click">
                                            <p class="text-general text-center my-2 f-15 f-600">
                                                Rango
                                            </p>
                                            <div class="row mx-0">
                                                <div class="col">
                                                    <el-input v-model="minCantBonos" placeholder="De" />
                                                </div>
                                                <div class="col">
                                                    <el-input v-model="maxCantBonos" placeholder="Hasta" />
                                                </div>
                                            </div>
                                            <div class="row mx-0 justify-content-center py-2">
                                                <div class="bg-general text-white f-17 px-4 py-1 br-10 cr-pointer" @click="accionFiltro('cant_bonos');filtrarRango('cant_bonos', 'minCantBonos', 'maxCantBonos')">
                                                    Filtrar
                                                </div>
                                            </div>
                                            <i slot="reference" class="mt-2 icon-arrow-collapse-horizontal text-general2 f-25 cr-pointer" />
                                        </el-popover>
                                    </div>
                                </div>
                            </template>
                            <template slot-scope="{row}">
                                {{ row.cant_bonos }}
                            </template>
                        </el-table-column>
                    </template>
                </tabla-general>
            </div>
        </div>
        <!-- Partials -->
    </section>
</template>

<script>
import notificaciones from '~/services/publicidad/notificaciones'
export default {
    data(){
        return {
            rangoFechas: [{
                value: 1,
                label: 'Ultima Semana'
            }, {
                value: 2,
                label: 'Ultimo Mes'
            }, {
                value: 3,
                label: 'Ultimos Tres Meses'
            }, {
                value: 4,
                label: 'Ultimo Año'
            }],
            valueFiltro: 1,
            tablaClientes:[],
            clientes:[],

            edad_filter: null,
            minEdad:null,
            maxEdad:null,

            cant_compras_filter: null,
            minCantCompras:null,
            maxCantCompras:null,

            valor_compra_filter:null,
            minValorCompras:null,
            maxValorCompras:null,

            fecha_compra_filter:null,
            rangoFechaCompra:null,

            antiguedad_filter:null,
            minAntiguedad:null,
            maxAntiguedad:null,

            calificaciones_filter:null,
            minCalificaciones:null,
            maxCalificaciones:null,

            calificacion_promedio_filter:null,
            minPromedioCalificaciones:null,
            maxPromedioCalificaciones:null,

            ultimo_acceso_filter:null,
            minDiasAcceso:null,
            maxDiasAcceso:null,

            accesos_filter:null,
            minCantAccesos:null,
            maxCantAccesos:null,

            cupones_filter:null,
            minCantCupones:null,
            maxCantCupones:null,

            bonos_filter:null,
            minCantBonos:null,
            maxCantBonos:null,

            cliente_filter: '',
            ciudad_filter: '',
            cedis_filter: '',
            leechero_filter: '',
            genero_filter: '',



            multipleSelection: [],

            tablaFiltered: [],
            loading:false,
            filtro: {},
            campana: {}
        }
    },
    computed:{
        prueba(){
            return this.tablaClientes.filter(el => !el.ciudad)
        }
    },
    watch: {
        cliente_filter(){
            this.delay(this.filtrar)
        },
        ciudad_filter(){
            this.delay(this.filtrar)
        },
        cedis_filter(){
            this.delay(this.filtrar)
        },
        leechero_filter(){
            this.delay(this.filtrar)
        },
        genero_filter(){
            this.delay(this.filtrar)
        },
    },
    async mounted(){
        await this.verCampanaBasico()
        await this.listarClientes()
    },
    methods: {
        async verCampanaBasico(){
            try {
                const { data } = await notificaciones.verCampanaBasico(this.$route.params.id_campana);
                this.campana = data.campana
            } catch (error){
                this.error_catch(error)
            }
        },
        async listarClientes(){
            try {
                this.loading = true
                let params ={
                    rango:this.valueFiltro
                }
                const {data} = this.campana && this.campana.tipo == 2 ? await notificaciones.buscarLeecheros(params) : await notificaciones.buscarClientes(params)
                this.clientes = data.clientes
                this.tablaClientes = _.cloneDeep(data.data)
            } catch (e){
                this.error_catch(e)
            }finally{
                this.loading =false
            }
        },
        selccionarClientes(seleccionados){
            //console.log(seleccionados);
            this.multipleSelection = seleccionados
            // _.forEach(seleccionados,(s)=>{
            //     this.multipleSelection.push(s.id_user)
            // })
        },
        async handleAnadir(){
            if(this.multipleSelection.length == 0) return this.notificacion('Alerta','Por favor seleccione al menos 1 usuario','info')
            if (this.multipleSelection.length == this.tablaClientes.length){
                await this.anadirSeleccionMultiple()
            } else {
                await this.anadirSeleccion()
            }

        },
        async anadirSeleccionMultiple(){
            try {
                this.loading = true

                let params = {
                    lanzamiento: this.$route.params.id_lanzamiento,
                    tipo: this.campana && this.campana.tipo,
                    rango:this.valueFiltro,
                    nombre: this.cliente_filter,
                    ciudad: this.ciudad_filter,
                    genero: this.genero_filter,
                    cedis: this.cedis_filter,
                    leechero: this.leechero_filter,
                    f_ul_compra: this.rangoFechaCompra ? this.rangoFechaCompra.toString() : null,
                    ...this.filtro

                }

                const {data} = await notificaciones.agregarUsuariosLanzamientoMultiple(params)
                if (data.exito){
                    this.notificacion('', 'Guardado correctamente', 'success')
                    this.goBack()
                }
            } catch (error){
                this.error_catch(error)
            } finally {
                this.loading = false
            }
        },
        async anadirSeleccion(){
            try {
                this.loading = true
                const model = {
                    lanzamiento: this.$route.params.id_lanzamiento,
                    usuarios: this.multipleSelection.map(el => el.id_user)
                }

                const {data} = await notificaciones.agregarUsuariosLanzamiento(model)
                if (data.exito){
                    this.notificacion('', 'Guardado correctamente', 'success')
                    this.goBack()
                }

            } catch (error){
                this.error_catch(error)

            } finally{
                this.loading = false
            }
        },
        accionFiltro(columna = ''){
            // let clientesInicial = _.cloneDeep(this.clientes)

            if(columna === 'edad'){
                this.edad_filter = (this.minEdad && this.maxEdad) ? this.minEdad + '-' + this.maxEdad : null
            }
            if(columna === 'cant_compras'){
                this.cant_compras_filter = (this.minCantCompras && this.maxCantCompras) ? this.minCantCompras + '-' + this.maxCantCompras : null
            }
            if(columna === 'valor_ultima_compra'){
                this.valor_compra_filter = (this.minValorCompras && this.maxValorCompras) ? this.minValorCompras + '-' + this.maxValorCompras: null
            }
            if(columna === 'fecha_ultima_compra'){
                this.fecha_compra_filter = this.rangoFechaCompra[0] + '-' + this.rangoFechaCompra[1]
            }
            if(columna === 'antiguedad'){
                this.antiguedad_filter = this.minAntiguedad + '-' + this.maxAntiguedad
            }
            if(columna === 'cant_calificaciones'){
                this.calificaciones_filter = this.minCalificaciones + '-' + this.maxCalificaciones
            }
            if(columna === 'calificacion_promedio_filter'){
                this.calificacion_promedio_filter = this.minPromedioCalificaciones + '-' + this.maxPromedioCalificaciones
            }
            if(columna === 'dias_ult_acceso'){
                this.ultimo_acceso_filter = this.minDiasAcceso + '-' + this.maxDiasAcceso
            }
            if(columna === 'cant_accesos'){
                this.accesos_filter = this.minCantAccesos + '-' + this.maxCantAccesos
            }
            if(columna === 'cant_cupones'){
                this.cupones_filter = this.minCantCupones + '-' + this.maxCantCupones
            }
            if(columna === 'cant_bonos'){
                this.bonos_filter = this.minCantBonos + '-' + this.maxCantBonos
            }
        },
        /**
         * Este metodo es para hacer filtro con el backend
         * de los tipos rango
         * el primer parametro es el campo como lo recibe el backend
         * y los otros dos son el valor de cada uno respectivamente
         * para agregar dinamicamente los valores en el filtro
         * que se va a hacer
         */
        async filtrarRango(campo, var1, var2){
            this.filtro = {
                ...this.filtro,
                [campo]: (this[var1] && this[var2]) ? [this[var1], this[var2]].toString(): null,
            }
            await this.filtrar()
        },

        removerRango(campo){
            delete this.filtro[campo]
            this.filtrar()
        },

        async filtrar(){

            try {
                this.loading = true
                let params = {
                    rango:this.valueFiltro,
                    nombre: this.cliente_filter,
                    ciudad: this.ciudad_filter,
                    genero: this.genero_filter,
                    cedis: this.cedis_filter,
                    leechero: this.leechero_filter,
                    f_ul_compra: this.rangoFechaCompra ? this.rangoFechaCompra.toString() : null,
                    ...this.filtro

                }
                const {data} = this.campana && this.campana.tipo == 2 ? await notificaciones.buscarLeecheros(params) : await notificaciones.buscarClientes(params)
                this.clientes = data.clientes
                this.tablaClientes = _.cloneDeep(data.data)
            } catch (e){
                this.error_catch(e)
            }finally{
                this.loading =false
            }
        },
        goBack(){
            this.$router.push(
                {
                    name: 'admin.publicidad.mensaje-notificacion.lanzamiento',
                    params: this.$route.params
                }
            )
        },

    }
}
</script>
<style lang="scss" scoped>
section{
    border-radius: 12px 12px 0px 0px;
}
</style>
