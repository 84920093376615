<template>
    <section v-if="viewRoutes" style="height:calc(100vh - 70px)">
        <tabs :tabs="rutas.filter(item => item.enable == true)" class="border-bottom" />
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 128px)">
            <router-view />
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return{
            viewRoutes: false,
            rutas: [
                {
                    titulo: 'Imágenes Pop up',
                    ruta: 'admin.publicidad.imagenes-pop-up',
                    can : 'publicidad_configuracion_imagenes_pop_up',
                    enable: true,
                },
                {
                    titulo: 'Código de vendedor',
                    ruta: 'admin.publicidad.codLeechero',
                    can : 'menu_leecheros_configuracion_codigo',
                    enable: false,
                },
                {
                    titulo: 'Aspecto correos electrónicos',
                    ruta: 'admin.publicidad.aspectCorreos',
                    can : 'menu_aspecto_configuracion_correos_electronicos',
                    enable: true,
                },
                // {
                //     titulo: 'Catálago de productos',
                //     ruta: 'admin.publicidad.catProductos',
                //     can : 'menu_aspecto_configuracion_catalogo_productos',
                //     enable: true,
                // },
                {
                    titulo: 'Mensajes de notificación',
                    ruta: 'admin.publicidad.mensajes-notificacion',
                    can : 'menu_aspecto_configuracion_catalogo_productos',
                    enable: true,
                }
            ],
        }
    },
    mounted(){
        this.rutas[1].titulo = `Código de ${ this.$config.vendedor }`
        this.rutas[1].enable = parseInt(this.$usuario.proyecto.tipo) === 2 ? true : false;
        this.viewRoutes = true;
    }
}
</script>
<style lang="scss" scoped>
a{
    text-decoration: none !important;
}
</style>
