<template>
    <section class="mx-2 mt-3">
        <div class="row mx-0">
            <div class="col-4">
                <div class="row mx-0 align-items-center mb-3">
                    <div class="btn-back" @click="goBack()">
                        <i class="icon-left f-12" />
                    </div>
                    <div class="col-auto text-general f-18 f-500">
                        {{ _.get(data, 'nombre') }}
                        <i :class="`${data.destacado ? 'icon-star text-warning' : 'icon-star-empty text-gris'}`" class="cr-pointer" @click="cambiarDestacado()" />
                    </div>
                </div>
                <div class="bg-white br-t-12 pt-3" style="height:calc(100vh - 150px)">
                    <div class="row mx-0">
                        <div class="col-12">
                            <label class="f-14 pl-3 pb-1 text-general f-500">Mensaje para los usuarios</label>
                            <el-input v-model="mensaje" :placeholder="`Escribe el mensaje que recibiran los ${$config.cliente} / ${$config.vendedor}`" class="w-100" type="textarea" :rows="8" show-word-limit maxlength="200" @change="onChangeInput()" />
                        </div>
                    </div>
                    <div class="row mx-0 mt-4">
                        <div class="col-12">
                            <p class="f-14 pl-3 pb-1 text-general f-500">Destino del enlace</p>
                        </div>
                        <div class="col-12">
                            <label class="f-12 text-general f-500 pl-2">Destino del enlace</label>
                            <el-select v-model="destino" size="small" class="w-100" @change="onChangeDestino()">
                                <el-option
                                v-for="item in destinos"
                                :key="item.id"
                                :label="item.label"
                                :value="item.id"
                                />
                            </el-select>
                        </div>
                        <div v-if="[3, 4].includes(destino)" class="col-12 mt-3">
                            <label class="f-12 text-general f-500 pl-2">Categoría / Promoción</label>
                            <el-select v-model="idDestino" size="small" class="w-100" @change="onChangeIdDestino()">
                                <el-option
                                v-for="item in arrDestinosIds"
                                :key="item.id"
                                :label="item.nombre"
                                :value="item.id"
                                />
                            </el-select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-8">
                <div class="row mx-0 align-items-center mb-3">
                    <el-tooltip placement="bottom" effect="light">
                        <div v-if="data.cedis && data.cedis.length" slot="content" class="text-center align-items-center mx-0">
                            <ul v-for="cedi in data.cedis" :key="cedi.id">
                                <li>
                                    {{ cedi.nombre }}
                                </li>
                            </ul>
                        </div>
                        <div class="row mx-0">
                            <i class="icon-cedis text-general f-17" />
                            <div class="col-auto px-2 f-15 text-general f-500">
                                {{ primerCedis }}
                            </div>
                            <div v-if="!_.isEmpty(data.cedis) && data.cedis.length > 1" class="count bg-general3 text-white px-1 f-15 d-middle-center">
                                +{{ !_.isEmpty(data.cedis) ? data.cedis.length - 1 : 0 }}
                            </div>
                        </div>
                    </el-tooltip>

                    <div class="btn-eliminar px-2 d-middle ml-auto cr-pointer" @click="$router.push({name: 'admin.publicidad.mensaje-notificacion.resultado', params: { id_campana: idCampana }})">
                        Resultados por usuario
                    </div>
                    <el-tooltip placement="bottom" content="Cambiar nombre de la campaña" effect="light">
                        <div class="btn-action border cr-pointer ml-2" @click="editarCampana()">
                            <i class="icon-pencil-outline f-20" />
                        </div>
                    </el-tooltip>
                    <el-tooltip placement="bottom" content="Eliminar Campaña" effect="light">
                        <div class="btn-action border cr-pointer ml-2" @click="eliminarCampana()">
                            <i class="icon-delete-outline f-20" />
                        </div>
                    </el-tooltip>
                </div>
                <div class="row mx-0 bg-white py-3 px-2 br-8">
                    <div class="col border-right text-center">
                        <p class="f-30 text-general f-600">
                            {{ countLanzamientos }}
                        </p>
                        <p class="text-general f-15">
                            Lanzamientos
                        </p>
                    </div>
                    <div class="col border-right text-center">
                        <p class="f-30 text-general f-600">
                            {{ _.get(data, 'total_destinatarios') }}
                        </p>
                        <p class="text-general f-15">
                            Destinatarios
                        </p>
                    </div>
                    <div class="col border-right text-center">
                        <p class="f-30 text-general f-600">
                            {{ _.get(data, 'destinatarios_unique') }}
                        </p>
                        <p class="text-general f-15">
                            Destinatarios diferentes
                        </p>
                    </div>
                    <div class="col text-center">
                        <p class="f-30 text-general f-600">
                            {{ _.get(data, 'total_apertura') }}%
                        </p>
                        <p class="text-general f-15">
                            Apertura
                            <el-tooltip placement="bottom" content="Porcentaje de aperturas de tipo notificaciones PUSH" effect="light">
                                <i class="icon-info-circled-alt f-18 text-general" />
                            </el-tooltip>
                        </p>
                    </div>
                </div>
                <div class="row mx-0 bg-white br-t-8 py-4 mt-3 align-items-center">
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-auto text-general f-18 f-600">
                        Lanzamientos
                    </div>
                    <div class="btn-general cr-pointer px-2 f-14 ml-auto" @click="crearLanzamiento()">
                        Crear Lanzamientos
                    </div>
                    <div class="col-3">
                        <el-input v-model="buscar" size="small" placeholder="Buscar Lanzamiento" class="w-100 br-20" />
                    </div>
                    <div class="col-12 px-0 mt-3">
                        <el-table
                        :data="lanzamientosFiltered"
                        header-cell-class-name="text-general f-16 text-center"
                        style="width: 100%"
                        >
                            <el-table-column
                            width="70"
                            >
                                <template slot-scope="{row}">
                                    <div class="d-inline-flex">
                                        <el-tooltip placement="bottom" content="Notificación PUSH" effect="light">
                                            <i v-if="row.push" class="icon-push f-12 text-general mx-1" />
                                        </el-tooltip>
                                        <el-tooltip placement="bottom" content="Envio SMS" effect="light">
                                            <i v-if="row.mensaje" class="icon-message-text-outline f-20 text-general mx-1" />
                                        </el-tooltip>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                            label="Nombre"
                            class-name="text-center"
                            width="180"
                            >
                                <template slot-scope="{row}">
                                    <p class="cr-pointer" @click="irLanzamiento(row)">
                                        {{ row.nombre }}
                                    </p>
                                </template>
                            </el-table-column>
                            <el-table-column
                            prop="envio"
                            label="Envío"
                            class-name="text-center"
                            width="180"
                            >
                                <template slot-scope="{row}">
                                    {{ row.fecha ? formatearFecha(row.fecha) : '-' }} <br />
                                    {{ row.fecha ? formatearFecha(row.fecha, 'hh:mm a') : '-' }}
                                </template>
                            </el-table-column>
                            <el-table-column
                            prop="destinatarios"
                            label="Destinatarios"
                            class-name="text-center"
                            />
                            <el-table-column
                            prop="apertura"
                            label="Apertura"
                            class-name="text-center"
                            >
                                <template slot-scope="{row}">
                                    {{ row.push ? `${row.apertura}%` : '-' }}
                                </template>
                            </el-table-column>
                            <el-table-column
                            label=""
                            class-name="text-center"
                            >
                                <template slot-scope="scope">
                                    <el-tooltip placement="bottom" effect="light" :content="estadosHash(scope.row.estado).tooltip">
                                        <i :class="`f-18 ${estadosHash(scope.row.estado).icon} ${estadosHash(scope.row.estado).color}`" />
                                    </el-tooltip>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-crear-lanzamiento ref="modalCrearLanzamiento" @saved="verCampana()" />
        <modal-crear-campana ref="modalCrearCampana" @saved="verCampana()" />
        <modal-eliminar ref="modalEliminarCampana" titulo="Eliminar campaña" @eliminar="onDelete()">
            <div class="row mx-0">
                <div class="col-12 text-center text-general py-2">
                    ¿Desea eliminar esta campaña? <br /> Perderá todas lanzamientos
                </div>
            </div>
        </modal-eliminar>
    </section>
</template>

<script>
import notificaciones from '~/services/publicidad/notificaciones'
import { mapActions } from 'vuex'

const estadosHash = {
    1: { icon: 'icon-pause-circle-outline', color: 'text-blue', tooltip: 'Pausado' },
    2: { icon: 'icon-clock', color: 'text-general', tooltip: 'Programado' },
    3: { icon: 'icon-ok-circled-outline', color: 'text-green', tooltip: 'Enviado' },
    4: { icon: 'icon-cancel-outline', color: 'text-general-red', tooltip: 'Cancelado' },
}

export default {
    beforeRouteEnter(to, from, next){
        next(vm => {
            vm.previousRoute = {...from}
        })
    },
    components: {
        modalCrearLanzamiento: () => import('./partials/modalCrearLanzamiento'),
        modalCrearCampana: () => import('./partials/modalCrearCampana')
    },
    data(){
        return {
            previousRoute: {},
            buscar: '',
            mensaje: '',
            destino: '',
            idDestino: '',
            arrDestinosIds: [],
            destinos: [
                { id: 1, label: 'Catálogo', path: '/catalogo' },
                { id: 2, label: 'Carrito', path: '/carrito' },
                { id: 3, label: 'Categoría', path: '/categoria' },
                { id: 4, label: 'Promoción', path: '/promocion' },
            ],
            value: '',
            data: {}
        }
    },
    computed: {
        countLanzamientos(){
            return !_.isEmpty(this.data.lanzamientos) ? this.data.lanzamientos.filter(el => el.estado == 3).length : 0
        },
        primerCedis(){
            return !_.isEmpty(this.data.cedis) ? _.head(this.data.cedis).nombre : ''
        },
        idCampana(){
            return this.$route.params.id_campana
        },
        lanzamientosFiltered(){
            return !_.isEmpty(this.data.lanzamientos) ? this.data.lanzamientos.filter(el => !this.buscar || el.nombre.toLowerCase().includes(this.buscar.toLowerCase())) : []
        }
    },
    async mounted(){
        await this.verCampana()
    },
    methods: {
        ...mapActions({
            listarCampanas: 'publicidad/notificaciones/listarCampanas',
            listarCarpetas: 'publicidad/notificaciones/listarCarpetas',
        }),
        crearLanzamiento(){
            this.$refs.modalCrearLanzamiento.toggle();
        },
        editarCampana(){
            let model = {
                nombre_campana: this.data.nombre,
                destacado: this.data.destacado == 1,
                cedis: this.data.cedis.map(el => el.id),
            }
            this.$refs.modalCrearCampana.editar(model);
        },
        eliminarCampana(){
            this.$refs.modalEliminarCampana.toggle();
        },
        estadosHash(state){
            return estadosHash[state]
        },
        async verCampana(){
            try {
                const { data } = await notificaciones.verCampana(this.idCampana);
                this.data = data.data
                this.mensaje = this.data.mensaje

                if (this.data.destino_app){
                    let destinoApp = JSON.parse(this.data.destino_app)
                    this.destino = destinoApp.id_destino
                    if ([3, 4].includes(this.destino)){
                        await this.getCategoriasPromociones()
                        let some = this.arrDestinosIds.some(el => el.id == destinoApp.param_destino)
                        this.idDestino = some ? destinoApp.param_destino : ''
                        if (!some){
                            await this.modificarCampana('destino_app', null)
                            this.destino = ''
                            this.idDestino = ''
                            this.arrDestinosIds = []
                        }
                    }
                }
            } catch (error){
                this.error_catch(error)
            }
        },
        async getCategoriasPromociones(){
            try {
                const { data } = this.destino == 3 ? await notificaciones.categorias() : await notificaciones.promociones();
                this.arrDestinosIds = data.data
            } catch (error){
                this.error_catch(error)
            }
        },
        async onChangeDestino(){
            try {
                this.idDestino = ''
                this.arrDestinosIds = []
                if ([1, 2].includes(this.destino)){
                    let find = this.destinos.find(el => el.id == this.destino)
                    let pathModel = {
                        id_destino: this.destino,
                        path_destino: find.path,
                        param_destino: null
                    }
                    this.modificarCampana('destino_app', JSON.stringify(pathModel))
                } else {
                    this.getCategoriasPromociones()
                }
            } catch (error){
                this.error_catch(error)
            }
        },
        async onChangeIdDestino(){
            let findDestino = this.destinos.find(el => el.id == this.destino)
            let pathModel = {
                id_destino: this.destino,
                path_destino: findDestino.path,
                param_destino: this.idDestino
            }

            this.modificarCampana('destino_app', JSON.stringify(pathModel))

        },
        async modificarCampana(campo, valor){
            try {
                let model = {
                    campo,
                    valor,
                }

                const { data } = await notificaciones.modificarCampana(model, this.idCampana)

                if (data.exito){
                    this.notificacion('', 'Actualizado correctamente', 'success')
                    this.verCampana()
                }

            } catch (error){
                this.error_catch(error)
            }
        },
        async cambiarDestacado(){
            let valor = this.data.destacado == 1 ? 0 : 1
            await this.modificarCampana('destacado', valor)
        },
        async onChangeInput(){
            await this.modificarCampana('mensaje', this.mensaje)
        },
        async onDelete(){
            try {
                const { data } = await notificaciones.eliminarCampana(this.idCampana);
                if (data.exito){
                    this.notificacion('', 'Eliminado correctamente', 'success')
                    await this.listarCampanas()
                    await this.listarCarpetas()
                    this.eliminarCampana()
                    this.goBack()
                }
            } catch (error){
                this.error_catch(error)
            }
        },
        goBack(){
            let { query } = this.previousRoute
            this.$router.push({name: 'admin.publicidad.mensajes-notificacion', query})
        },
        irLanzamiento({id: id_lanzamiento}){
            this.$router.push(
                {
                    name: 'admin.publicidad.mensaje-notificacion.lanzamiento',
                    params: {
                        id_campana: this.idCampana,
                        id_lanzamiento
                    }
                }
            )
        }
    }
}
</script>
<style lang="scss" scoped>
.count{
    min-width: 24px;
    min-height: 24px;
    border-radius: 20px;
}
</style>
