import axios from 'axios'

const API = 'etapas_pedidos'

const ESTADISTICAS = {
    listarGaficaEtapas(payload = {}){
        return axios(`${API}/listar-grafica-etapas`,{params: payload})
    },



}

export default ESTADISTICAS
