<template>
    <section>
        <div class="row mx-0 py-2" />
        <div class="row mx-0 border-bottom bg-white pt-2" style="border-radius:8px 8px 0px 0px">
            <router-link 
            :to="{name: 'admin.publicidad.imagenes-pop-up.clientes'}" 
            :class="`col-auto text-center py-2 ${$route.name == 'admin.publicidad.imagenes-pop-up.clientes' ? 'a-active' : 'text-general2'}`"
            >
                Aplicación de {{$config.cliente}}
            </router-link>
            <router-link 
            :to="{name: 'admin.publicidad.imagenes-pop-up.leecheros'}" 
            :class="`col-auto text-center py-2 ${$route.name == 'admin.publicidad.imagenes-pop-up.leecheros' ? 'a-active' : 'text-general2'}`"
            >
                Aplicación de {{$config.vendedor}}
            </router-link>
        </div>
        <router-view />
    </section>
</template>

<script>
export default {
    data(){
        return{

        }
    }
}
</script>
<style lang="scss" scoped>
a{
    text-decoration: none !important;
    &:hover{
        color: var(--color-general2);
    }
}
.a-active{
    border-bottom: 3px solid var(--text-general);
    color: var(--text-general);
    text-decoration: none !important;
    &:hover{
        color: var(--text-general);
    }
}
</style>