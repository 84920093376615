<template>
	<section class="position-relative">
		<cargando v-if="loading" />
		<div class="row align-items-center mx-0 py-2">
			<div class="br-50 ml-3 text-white bg-general3 d-middle-center cr-pointer" style="width:25px;height:25px;"
				@click="goBack()">
				<i class="icon-left f-12" />
			</div>
			<div class="col-auto text-general f-15">
				Buscar {{ campana.tipo == 1 ? 'clientes' : 'Vendedores' }} por criterios
			</div>
			<div class="col-auto ml-auto">
				<div class="bg-general text-white px-3 py-1 br-10 cr-pointer" @click="anadirSeleccion()">
					Añadir selección
				</div>
			</div>
		</div>
		<!--  -->
		<div class="row mx-0">
			<div class="col-12">
				<!--  -->
				<div class="bg-white br-8 h-700px">
					<!-- filtros -->
					<div class="row mx-0 p-3">
						<div class="col-auto px-0 my-auto text-general f-18 f-600">
							Resultados de usuarios ({{ clientes.length }})
						</div>
						<div class="col-auto ml-auto my-auto text-general f-15 px-0">
							Fecha de filtro
						</div>
						<div class="col-3">
							<el-select v-model="valueFiltro" placeholder="Select" class="w-100" size="small"
								@change="page = 0, changeDate()">
								<el-option v-for="item in rangoFechas" :key="item.value" :label="item.label"
									:value="item.value" />
							</el-select>
						</div>
						<div class="col-auto">
							<div class="btn-action border">
								<i class="icon-search f-18" />
							</div>
						</div>
						<div class="col-auto auto my-auto">
							<div class="d-middle-center text-white bg-dark br-50 f-14 w-157px h-26px cr-pointer"
								@click="abrirModalSeleccionados">
								<p>{{ clientesSelected.length }} seleccionados</p>
							</div>
						</div>
						<div class="col-auto auto my-auto position-relative">
							<div class="d-middle-center text-general border bg-fondo br-50 f-14 w-96px h-27px cr-pointer"
								@click="abrirModalFiltro">
								<i class="icon-filter-outline" />
								<p>Filtrar</p>
							</div>
						</div>
					</div>
					<div class="main-div w-100 h-700px overflow-auto custom-scroll position-relative d-flex flex-column">
						<div class="headers">
							<div class="sticky-top-bg d-flex h-46px">
								<div class="title-nombre my-auto wf-290px px-2 h-46px" style="">
									<div class="d-middle h-100">
										<el-checkbox v-model="checkAllUsers" @change="selectAll" />
										<p class="f-16 fr-medium text-general ml-2">Nombre</p>
									</div>
								</div>
								<div class="title-ciudad my-auto wf-150px px-2">
									<p class="f-16 fr-medium text-general">Ciudad</p>
								</div>
								<div class="title-genero my-auto wf-105px px-2">
									<p class="f-16 fr-medium text-general">Genero</p>
								</div>
								<div class="title-edad my-auto wf-105px px-2">
									<p class="f-16 fr-medium text-general">Edad</p>
								</div>
								<div class="title-compras my-auto wf-105px px-2">
									<p class="f-16 fr-medium text-general">Compras</p>
								</div>
								<div class="title-compras my-auto wf-180px px-2">
									<p class="f-16 fr-medium text-general">Valor últ. compra</p>
								</div>
								<div class="title-fecha-ultima-compra my-auto wf-180px px-2">
									<p class="f-16 fr-medium text-general">Fecha últ. compra</p>
								</div>
								<div class="title-cant-carrito my-auto wf-200px px-2">
									<p class="f-16 fr-medium text-general">Productos en carrito</p>
								</div>
								<div class="title-envio-campana my-auto wf-200px px-2">
									<p class="f-16 fr-medium text-general">Vendedor principal</p>
								</div>
								<div class="title-antiguedad my-auto wf-180px px-2">
									<p class="f-16 fr-medium text-general">Antigüedad (Meses)</p>
								</div>
								<div class="title-calificaciones my-auto wf-180px px-2">
									<p class="f-16 fr-medium text-general">Calificaciones</p>
								</div>
								<div class="title-promedio my-auto wf-220px px-2">
									<p class="f-16 fr-medium text-general">Calificaciones promedio</p>
								</div>
								<div class="title-promedio my-auto wf-180px px-2">
									<p class="f-16 fr-medium text-general">últ. acceso (Días)</p>
								</div>
								<div class="title-promedio my-auto wf-180px px-2">
									<p class="f-16 fr-medium text-general"># de accesos</p>
								</div>
								<div class="title-promedio my-auto wf-180px px-2">
									<p class="f-16 fr-medium text-general">Uso de cupones</p>
								</div>
								<div class="title-promedio my-auto wf-180px px-2">
									<p class="f-16 fr-medium text-general">Uso de bonos</p>
								</div>
							</div>
						</div>
						<div v-for="(item, index) in clientes" :key="index"
							class="each-iteam d-flex h-50px border-bottom bg-white" style="width: fit-content;">
							<div class="title-nombre-fixed my-auto wf-290px px-2 h-50px bg-white" style="">
								<div class="d-middle h-100">
									<el-checkbox v-model="item.selected" @change="selectUser(item, $event)" />
									<p class="f-16 fr-light text-general ml-2 text-capitalize">{{ item.nombre }}</p>
								</div>
							</div>
							<div class="title-ciudad my-auto wf-150px px-2">
								<p class="f-16 fr-light text-general">{{ item.ciudad_nombre }}</p>
							</div>
							<div class="title-genero my-auto wf-105px px-2">
								<p class="f-16 fr-light text-general">{{ item.genero == 1 ? 'Otro' : item.genero == 2 ?
									'Hombre' : 'Mujer' }}</p>
							</div>
							<div class="title-edad my-auto wf-105px px-2">
								<p class="f-16 fr-light text-general">{{ item.edad }}</p>
							</div>
							<div class="title-compras my-auto wf-105px px-2">
								<p class="f-16 fr-light text-general">{{ item.cant_compras }}</p>
							</div>
							<div class="title-compras my-auto wf-180px px-2">
								<p class="f-16 fr-light text-general">{{ convertMoney(item.valor_ultima_compra,
									$usuario.idm_moneda) }}</p>
							</div>
							<div class="title-fecha-ultima-compra my-auto wf-180px px-2">
								<p class="f-16 fr-light text-general">{{ item.fecha_ultima_compra || '--' }}</p>
							</div>
							<div class="title-cant-carrito my-auto wf-200px px-2">
								<p class="f-16 fr-light text-general">{{ item.prod_carrito == 1 ? 'SI' : 'NO' }}</p>
							</div>
							<div class="title-envio-campana my-auto wf-200px px-2">
								<p class="f-16 fr-light text-general">{{ item.vendedor }}</p>
							</div>
							<div class="title-antiguedad my-auto wf-180px px-2">
								<p class="f-16 fr-light text-general">{{ item.antiguedad }}</p>
							</div>
							<div class="title-calificaciones my-auto wf-180px px-2">
								<p class="f-16 fr-light text-general">{{ item.cant_calificaciones }}</p>
							</div>
							<div class="title-promedio my-auto wf-220px px-2">
								<p class="f-16 fr-light text-general">{{ redondearNumero(item.promedio_calificacion, 1) ||
									'--' }}</p>
							</div>
							<div class="title-promedio my-auto wf-180px px-2">
								<p class="f-16 fr-light text-general"> {{ item.dias_ult_acceso || '--' }} </p>
							</div>
							<div class="title-promedio my-auto wf-180px px-2">
								<p class="f-16 fr-light text-general">{{ item.cant_accesos }}</p>
							</div>
							<div class="title-uso-cupones my-auto wf-180px px-2">
								<p class="f-16 fr-light text-general"> {{ item.cant_cupones }} </p>
							</div>
							<div class="title-uso-bonos my-auto wf-180px px-2">
								<p class="f-16 fr-light text-general">{{ item.cant_bonos }}</p>
							</div>
						</div>
					</div>
					<div type="button" class="br-10 p-1 bg-gr-red text text-center m-3" @click="listarClientes">
						Ver Mas...
					</div>
				</div>
			</div>
		</div>
		<!-- Partials -->
		<modal-anadir-seleccionar ref="modalAnadirSeleccion" @update="updateList" />
		<modal-ver-seleccionados ref="refModalVerSeleccionados" :clientes="clientesSelected" @remove="removeUserSeleted" />
		<modal-filtro-usuarios ref="refModalFiltroUsuarios" @filterAction="applyFiter" />
	</section>
</template>

<script>
import { mapGetters } from 'vuex';
import notificaciones from '~/services/publicidad/notificaciones';
import modalVerSeleccionados from './partials/modalVerSeleccionados.vue';
import modalFiltroUsuarios from './partials/modalFiltroUsuarios.vue';
export default {
	components: {
		// modalAnadirSeleccionar: () => import('../partials/modalAnadirSeleccion'),
		modalVerSeleccionados,
		modalFiltroUsuarios,
	},
	data() {
		return {
			rangoFechas: [{
				value: 1,
				label: 'Ultima Semana'
			}, {
				value: 2,
				label: 'Ultimo Mes'
			}, {
				value: 3,
				label: 'Ultimos Tres Meses'
			}, {
				value: 4,
				label: 'Ultimo Año'
			}],
			filter: {},
			valueFiltro: 1,
			usersSelected: [{
				id_user: 2
			}],
			clientes: [],
			clientesSelected: [],
			checkAllUsers: false,
			loading: false,
			campana: {},
			key: true,
			page: 0
		}
	},
	async mounted() {
		await this.verCampanaBasico()
		await this.listarClientes()
	},
	methods: {
		changeDate(){
			this.clientes = []
			this.listarClientes()
		},
		async verCampanaBasico() {
			try {
				const { data } = await notificaciones.verCampanaBasico(this.$route.params.id_campana);
				this.campana = data.campana
			} catch (error) {
				this.error_catch(error)
			}
		},
		async listarClientes() {
			try {
				this.loading = true
				this.page += 1
				let params = {
					rango: this.valueFiltro,
					page: this.page,
					...this.filter
				}
				const { data } = await notificaciones.buscarClientes(params, this.$route.params.id_lanzamiento)
				this.clientes.push(...data.clientes.data)
				if (this.checkAllUsers) {
					this.clientes.forEach(element => element.selected = true);
				}
				if (this.usersSelected.length > 0) {
					this.verificarUsuarios()
				}
			} catch (e) {
				this.error_catch(e)
			} finally {
				this.loading = false
			}
		},
		applyFiter(filter) {
			this.page = 0;
			this.clientes.forEach(element => element.selected = false);
			this.checkAllUsers = false
			this.clientes = [];
			this.filter = filter;
			this.listarClientes();
		},
		abrirModalSeleccionados() {
			this.$refs.refModalVerSeleccionados.toggle()
		},
		abrirModalFiltro() {
			this.$refs.refModalFiltroUsuarios.toggle()
		},
		updateList() {
			this.loading = true
			this.clientes = []
			this.checkAllUsers = false
			this.usersSelected = []
			this.page = 0
			this.filter = {}
			setTimeout(() => {
				this.listarClientes()
			}, 2000)
		},
		async selectAll(event) {
			try {
				if (event) {
					const usersAdd =  this.clientesSelected.map(e => e.id_user);
					this.loading = true
					let params = {
						rango: this.valueFiltro,
						usersAdd: usersAdd.length > 0 ? usersAdd : [2],
						...this.filter
					}
					const { data } = await notificaciones.checkAllClientes(params, this.$route.params.id_lanzamiento)
					if(this.clientesSelected.length == 0){
						this.clientesSelected = data.data;
					}else{
						this.clientesSelected = this.clientesSelected.concat(data.data);
					}
					this.clientes.forEach(element => element.selected = true);
				} else {
					this.clientes.forEach(element => element.selected = false);
				}
			} catch (e) {
				this.error_catch(e)
			} finally {
				this.loading = false
			}
		},
		selectUser(item, event) {
			if (event) {
				const idx = this.clientesSelected.findIndex((e) => e.id_user == item.id_user)
				if (idx != -1) return this.notificacion('', 'Usuario ya agregado', 'warning')
				this.clientesSelected.push({ nombre: item.nombre, id_user: item.id_user })
			} else {
				this.removeUserSeleted(item.id_user)
			}
		},
		async anadirSeleccion() {
			if (this.clientesSelected.length === 0) {
				this.notificacion('Alerta', `Por favor seleccione almenos 1 ${this.$config.cliente}`, 'info')
				return
			}
			const model = {
				lanzamiento: this.$route.params.id_lanzamiento,
				usuarios: this.clientesSelected.map(el => el.id_user)
			}

			const { data } = await notificaciones.agregarUsuariosLanzamiento(model)
			if (data.exito) {
				this.notificacion('', 'Guardado correctamente', 'success')
				this.goBack()
			}
		},
		removeUserSeleted(id) {
			const idx = this.clientesSelected.findIndex((e) => e.id_user == id)
			this.clientesSelected.splice(idx, 1)
			let item = this.clientes.find((e) => e.id_user == id)
			item.selected = false
		},
		verificarUsuarios() {
			this.clientes.forEach(element => {
				element.selected = this.clientesSelected.some(e => e.id_user === element.id_user);
			});
		},
		goBack() {
			this.$router.push(
				{
					name: 'admin.publicidad.mensaje-notificacion.lanzamiento',
					params: this.$route.params
				}
			)
		},

	}
}
</script>
<style lang="scss" scoped>
section {
	border-radius: 12px 12px 0px 0px;
}

//
.h-700px {
	height: 700px;
}

.h-600px {
	height: 600px;
}

.sticky-top-bg {
	width: fit-content;
	position: sticky;
	top: 0;
	z-index: 10;
	background: #F6F9FB;
}

.main-div {
	.headers {
		@extend .sticky-top-bg;

		.title {
			&-nombre {
				position: sticky;
				left: 0;
				z-index: 5;
				background: #F6F9FB;
			}
		}
	}

	.title-nombre-fixed {
		position: sticky;
		left: 0;
		z-index: 5;
	}
}
</style>

