<template>
    <section>
        <div class="custom-scroll overflow-auto pr-3" style="height:calc(100vh - 133px)">
            <div class="row mx-0 mt-5">
                <div class="col-auto d-middle text-general f-17 f-500">
                    Rango de análisis
                </div>
                <div class="col-2">
                    <el-select v-model="rango" placeholder="Seleccionar" size="small" class="w-100">
                        <el-option
                        v-for="item in rangos"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.id"
                        />
                    </el-select>
                </div>
                <div class="col-3 px-0 mx-2">
                    <div class="date-picker-edit">
                        <el-date-picker
                        v-model="fecha"
                        class="w-100"
                        size="small"
                        format="dd-MM-yyyy"
                        value-format="yyyy-MM-dd"
                        :disabled="disable_fecha"
                        popper-class="date-picker-edit-popper"
                        type="daterange"
                        unlink-panels
                        start-placeholder="Fecha inicio"
                        end-placeholder="Fecha fin"
                        />
                    </div>
                </div>
                <div class="col-auto">
                    <div class="btn-general bg-general br-8 text-white p-1 px-3 f-14" @click="listarGrafica">
                        Mostrar informe
                    </div>
                </div>
            </div>
            <div class="row mx-0 mt-5">
                <div class="col-12 bg-white py-3 br-t-12">
                    <p class="f-18 text-general f-600">
                        Tiempo promedio de etapas del pedido entre cedis (Horas)
                    </p>
                </div>
                <div class="col mt-3 px-0">
                    <el-table :data="tableData" stripe style="width: 100%">
                        <el-table-column class-name="text-right f-600 text-general f-600 f-16" prop="cedis" width="170" />
                        <el-table-column width="500">
                            <template slot="header">
                                <div class="d-flex mx-0 align-items-center justify-content-center">
                                    <div v-for="(data,d) in steps_header" :key="d" class="d-flex align-items-center">
                                        <el-tooltip placement="bottom" :content="data.mensaje" effect="light">
                                            <div class="border-general rounded-circle text-general d-middle-center step-container">
                                                <i :class="`icon-${data.icon} f-23`" />
                                            </div>
                                        </el-tooltip>
                                        <div v-show="steps_header.length - 1 != d " class="line-step" />
                                    </div>
                                </div>
                            </template>
                            <template slot-scope="scope">
                                <div class="row mx-0 justify-content-around">
                                    <!-- <div class="col-auto" :class="scope.row.mayor_creado ? 'text-red' : scope.row.menor_creado ? 'text-general': ''">
                                        {{ scope.row.creado }}
                                    </div> -->
                                    <div class="col-auto" :class="scope.row.mayor_confirmado ? 'text-red' : scope.row.menor_confirmado ? 'text-general': ''">
                                        {{ agregarSeparadoresNumero(scope.row.confirmado, 1) }}
                                    </div>
                                    <div class="col-auto" :class="scope.row.mayor_alistando? 'text-red' : scope.row.menor_alistando? 'text-general': ''">
                                        {{ agregarSeparadoresNumero(scope.row.alistando, 1) }}
                                    </div>
                                    <div class="col-auto" :class="scope.row.mayor_empacado ? 'text-red' : scope.row.menor_empacado ? 'text-general': ''">
                                        {{ agregarSeparadoresNumero(scope.row.empacado, 1) }}
                                    </div>
                                    <div class="col-auto" :class="scope.row.mayor_enviado ? 'text-red' : scope.row.menor_enviado ? 'text-general': ''">
                                        {{ agregarSeparadoresNumero(scope.row.enviado, 1) }}
                                    </div>
                                    <div class="col-auto" :class="scope.row.mayor_finalizado ? 'text-red' : scope.row.menor_finalizado ? 'text-general': ''">
                                        {{ agregarSeparadoresNumero(scope.row.finalizado, 1) }}
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column class-name="text-center" label="Tiempo promedio Total" width="180">
                            <template slot-scope="scope">
                                {{ agregarSeparadoresNumero(scope.row.tiempo_promedio_total, 1) }}
                            </template>
                        </el-table-column>
                        <el-table-column class-name="text-center" label="Pedidos Totales" width="180">
                            <template slot-scope="scope">
                                {{ agregarSeparadoresNumero(scope.row.pedidos_totales, 1) }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="valor_promedio" class-name="text-center" label="Valor Promedio">
                            <template slot-scope="{row}">
                                {{ separadorNumero(row.valor_promedio) }}
                            </template>
                        </el-table-column>
                        <el-table-column class-name="text-center" label="Desfase de Entrega">
                            <template slot-scope="{row}">
                                <p :class="row.mayor_desfase ? 'text-red' : row.menor_desfase ? 'text-general': ''">
                                    {{ agregarSeparadoresNumero(row.desfase_entrega) }} Horas
                                </p>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div class="row mx-0 mt-5 align-items-center">
                <div class="col-12 mb-4 bg-white py-3 br-t-12">
                    <p class="text-general f-18 f-600">
                        Tiempo promedio de etapas del pedido por cedis (Horas)
                    </p>
                </div>
            </div>
            <div v-for="(tiempo, t) in tiempo_promedio" :key="`tiemp-${t}`" class="row mx-0 align-items-center my-5 py-4 bg-white br-b-12">
                <div class="col-md-3 col-lg-2 col-xl-2 col-sm-2  text-right text-hours f-500">
                    {{ tiempo.cedis }}
                </div>
                <div class="col px-0 bg-line bg-whitesmoke2 position-relative">
                    <div v-for="(step,s) in tiempo.steps" :key="`st-${s}`" class="point-step bg-general3 position-absolute" :style="`width:${step.porcentaje}%`">
                        <el-tooltip placement="bottom" effect="light">
                            <template slot="content">
                                <div class="row mx-0">
                                    <div class="col px-1">
                                        <p class="f-14" v-text="step.etapa" />
                                        <p class="f-14">
                                            {{ agregarSeparadoresNumero(step.hrs, 1) }} hrs
                                        </p>
                                        <p class="f-14">
                                            Acumulado {{ agregarSeparadoresNumero(step.acumulado, 1) }}
                                        </p>
                                    </div>
                                </div>
                            </template>
                            <div class="step text-gr-general">
                                <i :class="`icon-${step.icon}`" />
                                <!-- stick down -->
                                <div class="stick-up" />
                                <!-- stick up -->
                                <div class="stick-down" />
                            </div>
                        </el-tooltip>
                    </div>
                </div>
                <div class="col-auto text-right text-hours f-600">
                    {{ agregarSeparadoresNumero(tiempo.tiempo_promedio_total, 1) }} Hrs.
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import moment from 'moment'
import Estadisticas from '~/services/estadisticas/etapas_pedido'
import { mapGetters } from 'vuex'

export default {
    data(){
        return {
            rangos: [
                { id: 1, nombre: "Hoy"},
                { id: 2, nombre: "Ayer"},
                { id: 3, nombre: "Ultima semana"},
                { id: 4, nombre: "Semana anterior"},
                { id: 5, nombre: "Ultimo mes"},
                { id: 6, nombre: "Mes anterior"},
                { id: 7, nombre: "Ultimo año"},
                { id: 8, nombre: "Personalizado"},
            ],
            rango: 5,
            fecha: [moment().subtract(1, 'month').toDate(),new Date()],
            disable_fecha: true,
            model: {
                fecha: null
            },

            tableData: [],
            steps_header: [
                {
                    icon: 'ok-circled',
                    mensaje: 'Confirmado'
                },
                {
                    icon: 'cart-arrow-down',
                    mensaje: 'Alistamiento'
                },
                {
                    icon: 'shopping-outline',
                    mensaje: 'Empacado'
                },
                {
                    icon: 'truck-fast-outline',
                    mensaje: 'Enviado'
                },
                {
                    icon: 'shopping',
                    mensaje: 'Entregado'
                }
            ],
            tiempo_promedio: []
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
        })
    },
    watch:{
        rango(val){
            switch (val){
            case 1:
                this.fecha = []
                this.fecha[0] = new Date()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 2:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'day').toDate()
                this.fecha[1] = moment().subtract(1, 'day').toDate()
                this.disable_fecha = true
                break;
            case 3:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 4:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').startOf('week').toDate()
                this.fecha[1] = moment().subtract(1, 'week').endOf('week').toDate()
                this.disable_fecha = true
                break;
            case 5:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 6:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').startOf('month').toDate()
                this.fecha[1] = moment().subtract(1, 'month').endOf('month').toDate()
                this.disable_fecha = true
                break;
            case 7:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'year').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 8:
                this.fecha = []
                this.fecha[0] = moment().startOf('month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = false
                break;
            default:
                break;
            }
        },
        id_cedis(){
            this.listarGrafica()
        }
    },
    mounted(){
        this.listarGrafica()
    },
    methods:{
        async listarGrafica(){
            try {
                const params = {
                    fecha_inicio: moment(this.fecha[0]).format('Y-MM-DD'),
                    fecha_fin: moment(this.fecha[1]).format('Y-MM-DD'),
                }
                const {data} = await Estadisticas.listarGaficaEtapas(params)
                this.tableData = data.fila
                var copyData = data.fila
                let steps = copyData.map(e => {
                    e.disable= false
                    e.hasDatos = 0
                    return e.steps.map(pasos => {
                        e.hasDatos += pasos.porcentaje
                        return pasos.porcentaje
                    });
                });
                let filtro = copyData.filter(e => {
                    return e.hasDatos > 0
                });
                this.tiempo_promedio = filtro

                // console.log('steps', steps, 'filtro', filtro);
            } catch (e){
                this.error_catch(e)
            }
        }
    }

}
</script>

<style lang="scss" scoped>
.br-12{
    border-radius: 12px ;
}
.text-red{
    color:#FF2525;
}
.step-container{
    width: 40px;
    height: 40px;
    border: 2px solid var(--text-general);
    i::before{
        display: contents !important;
    }
}
.line-step{
    border: 1px solid var(--text-general);
    width: 40px;
    border-radius: 0px;
    height: 0px;
}
.text-hours{
    color: #7F7D7D !important;
    font-size: 18px;
}
.bg-line{
    height: 5px;
    border-radius: 5px;
    .point-step{
        height: 100%;
        border-radius: 50px;
        left: 0px;
        &:nth-child(even){
            .step{
                bottom: -58px;
                .stick-up{
                    border: 1px solid var(--text-general);
                }
            }
        }
        &:nth-child(odd){
            .step{
                top: -58px;
                .stick-down{
                    border: 1px solid var(--text-general);
                }
            }
        }
        .step{
            /* Position defaul of the step */
            position: absolute;
            right:-19px;
            /* Position defaul of the step  */
            width: 40px;
            height: 40px;
            border: 2px solid var(--text-general);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 24px;
            border-radius: 50px;
            .stick-up{
                top: -20px;
                position: absolute;
                height: 20px;
            }
            .stick-down{
                bottom: -20px;
                position: absolute;
                height: 20px;
            }
        }
    }

}
</style>
