<template>
    <section>
        <div class="row mx-0 pb-3 align-items-center px-2">
            <div class="btn-back" @click="atras">
                <i class="icon-left f-12" />
            </div>
            <div class="col text-general f-18 pl-4 f-600">
                Galería de imágenes de {{ tipo == 1 ? $config.cliente : $config.vendedor }}
            </div>
        </div>
        <div class="row mx-0">
            <div class="col-4 px-0 br-t-12 bg-white custom-scroll overflow-auto" style="height:calc(100vh - 159px);box-shadow: 0px 1px 4px #00000014;">
                <div class="row mx-0 py-2 border-bottom ">
                    <div class="col pr-2">
                        <el-input v-model="buscarCarpeta" clearable placeholder="Buscar carpeta" class="w-100 br-20" size="small" />
                    </div>
                    <div class="col-auto px-2">
                        <div class="bg-general text-white br-8 shadow d-middle-center cr-pointer" style="width:32px;height:32px;" @click="crearCarpeta()">
                            <i class="icon-folder-plus f-22" />
                        </div>
                    </div>
                </div>
                <div v-for="data in carpetasFilter" :key="data.id" class="row mx-0 align-items-center px-4 py-2 border-bottom cr-pointer" @click="carpeta_activa = data">
                    <div :class="`${data.id == carpeta_activa.id ? 'bg-general' : ''} br-20`" style="height:38px;width:10px;" />
                    <div class="col f-15 f-600 text-general">
                        {{ data.nombre }}
                    </div>
                    <div class="col-auto px-2">
                        <i :class="`icon-angle-right f-18 ${ data.id == carpeta_activa.id ? 'text-general' : 'text-gris' } `" />
                    </div>
                </div>
            </div>
            <div v-if="carpeta_activa.id != null" class="col bg-white br-t-12 ml-3 px-0">
                <div class="row mx-0 py-2 justify-content-end">
                    <div class="col-auto">
                        <div class="btn-general px-2" @click="usarPublicidadMultiple()">
                            Usar las seleccionadas
                        </div>
                    </div>
                    <div class="col-4">
                        <el-input v-model="buscar" clearable placeholder="Buscar Imagen" class="w-100 br-20" size="small" />
                    </div>
                    <div class="col-auto px-0">
                        <el-tooltip placement="bottom" content="Añadir Imagen" effect="light">
                            <div class="btn-red" @click="anadirImagen">
                                <i class="icon-plus" />
                            </div>
                        </el-tooltip>
                    </div>
                    <div class="col-auto px-0 ml-2">
                        <div class="btn-action border" @click="editarCarpeta">
                            <i class="icon-pencil-outline" />
                        </div>
                    </div>
                    <div class="col-auto px-2">
                        <div class="btn-action border" @click="eliminarCarpeta">
                            <i class="icon-delete-outline" />
                        </div>
                    </div>
                </div>
                <div class="custom-scroll overflow-auto" style="height:calc(100vh - 207px)">
                    <div class="row mx-0 pt-3">
                        <div v-for="publi in publicidadesFilter" :key="publi.id" class="col-auto mb-5">
                            <div class="d-flex justify-content-center position-relative">
                                <img :src="publi.imagen_firmada" class="border obj-cover br-8 shadow" width="175" height="175" />
                                
                                <div 
                                class="d-middle-center text-white position-absolute"
                                style="width:22px;height:22px;bottom:0px;right:0px;top:10px;left:10px"
                                >
                                    <el-checkbox v-model="publi.checked" />
                                </div>
                                <div
                                class="bg-general3 rounded-circle cr-pointer d-middle-center text-white position-absolute"
                                style="width:22px;height:22px;bottom:12px;right:90px;"
                                @click="editarImagen(publi)"
                                >
                                    <i class="icon-pencil-outline f-15" />
                                </div>
                                <div
                                class="bg-general3 rounded-circle cr-pointer d-middle-center text-white position-absolute"
                                style="width:22px;height:22px;bottom:12px;right:60px;"
                                @click="eliminarImagen(publi.id)"
                                >
                                    <i class="icon-delete-outline f-15" />
                                </div>
                            </div>
                            <p class="text-general py-2 f-15 text-center">
                                {{ publi.titulo }}
                            </p>
                            <div class="bg-general text-white shadow br-8 py-1 text-center cr-pointer" @click="usarPublicidad(publi.id)">
                                Usar
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-anadir-imagen ref="modalAnadirImagen" @update="getCarpetas" />
        <modal-editar-imagen ref="modalEditarImagen" @update="editarAnuncio" />
        <modal ref="modalCrearCarpeta" titulo="Crear carpeta de galería" :cargado-modal="loading" icon="carpeta_nueva" no-aceptar adicional="Guardar" @adicional="guardarPublicidadCarpeta">
            <ValidationObserver ref="form" class="w-100">
                <div class="row mx-0 justify-center mb-4 mt-3">
                    <div class="col-10">
                        <ValidationProvider v-slot="{ errors }" rules="required|max:40" class="w-100" name="nombre">
                            <p class="pl-3 text-general f-14">Nombre de la carpeta</p>
                            <el-input v-model="nombre_carpeta" placeholder="Nombre" class="w-100" />
                            <p class="f-11 text-danger">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                </div>
            </ValidationObserver>
        </modal>
        <modal ref="modalEditarCarpeta" titulo="Editar carpeta de galería" :cargado-modal="loading" icon="carpeta_nueva" no-aceptar adicional="Editar" @adicional="editarPublicidadCarpeta">
            <ValidationObserver ref="form" class="w-100">
                <div class="row mx-0 justify-center mb-4 mt-3">
                    <div class="col-10">
                        <ValidationProvider v-slot="{ errors }" rules="required|max:40" class="w-100" name="nombre">
                            <p class="pl-3 text-general f-14">Nombre de la carpeta</p>
                            <el-input v-model="nombre_carpeta" placeholder="Nombre" class="w-100" />
                            <p class="f-11 text-danger">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                </div>
            </ValidationObserver>
        </modal>
        <modal-eliminar ref="modalEliminar" titulo="Eliminar Carpeta" :cargado-modal="loading" no-aceptar adicional="Eliminar" @eliminar="eliminarPublicidadCarpeta">
            <div class="row mx-0 justify-center mb-4">
                <div class="col-12 f-15 text-general text-center">
                    ¿Desea eliminar esta carpeta? <br /> Perderá todas las imágenes que tengas guardadas aquí
                </div>
            </div>
        </modal-eliminar>
        <modal-eliminar ref="modalEliminarImagen" titulo="Eliminar Imagen" :cargado-modal="loading" no-aceptar adicional="Eliminar" @eliminar="eliminarPublicidad">
            <div class="row mx-0 justify-center mb-4">
                <div class="col-12 f-15 text-general text-center">
                    ¿Desea eliminar esta imagen?
                </div>
            </div>
        </modal-eliminar>
    </section>
</template>

<script>
import Publicidad from "~/services/publicidad/publicidad_admin"
export default {
    components: {
        modalAnadirImagen: () => import('./partials/modalAnadirImagen'),
        modalEditarImagen: () => import('./partials/modalEditarImagen')
    },
    data(){
        return {
            buscar: '',
            buscarCarpeta: '',
            carpeta_activa: {
                id: null,
                nombre: null,
                publicidades: [],
            },
            nombre_carpeta: '',
            loading: false,
            carpetas: []
        }
    },
    computed: {
        tipo(){
            return this.$route.params.tipo
        },
        id_cedis(){
            return this.$route.params.id_cedis
        },
        carpetasFilter(){
            return this.carpetas.filter(el =>
                !this.buscarCarpeta
                || el.nombre.toLowerCase().includes(this.buscarCarpeta.toLowerCase())
            )
        },
        publicidadesFilter(){
            return this.carpeta_activa.publicidades.filter(el =>
                !this.buscar
                || el.titulo.toLowerCase().includes(this.buscar.toLowerCase())
            )
        },
    },
    mounted(){
        this.getCarpetas()
    },
    methods: {
        async getCarpetas(){
            try {
                const params = {
                    tipo: this.tipo,
                }
                const {data} = await Publicidad.getCarpetas(params)
                this.carpetas = data.carpetas.map(el => {
                    el.publicidades = el.publicidades
                        .filter(ele => !ele.publicidades_cedis.some(elm => elm.id_cedis == this.id_cedis))
                        .map(el => ({ ...el, checked: false }))
                    return el
                })

                if (this.carpeta_activa.id){
                    this.carpeta_activa = this.carpetas.find(el => el.id == this.carpeta_activa.id)
                }

            } catch (e){
                this.error_catch(e)
            }
        },
        anadirImagen(){
            this.$refs.modalAnadirImagen.addImgCliente({id_carpeta: this.carpeta_activa.id, tipo: this.tipo,id_cedis: this.id_cedis, showSection : false});
        },
        editarImagen(anuncio){
            this.$refs.modalEditarImagen.addImgCliente({anuncio, tipo: this.tipo,id_cedis: this.id_cedis});
        },
        agregarAnuncio(model){
            // this.carpeta_activa.publicidades.push(model)
        },
        editarAnuncio(model){
            let index = this.carpeta_activa.publicidades.findIndex(el => el.id == model.id)
            if(this.carpeta_activa.id != model.id_carpeta){
                this.carpeta_activa.publicidades.splice(index, 1)
                let indexCarpeta = this.carpetas.findIndex(el => el.id == model.id_carpeta)
                this.carpetas[indexCarpeta].publicidades.push(model)
            } else {
                this.$set(this.carpeta_activa.publicidades, index, model)
            }
        },
        crearCarpeta(){
            this.$refs.modalCrearCarpeta.toggle();
        },
        async usarPublicidad(id){
            try {
                this.loading = true
                let model = {}

                model.id_cedis = this.id_cedis
                model.tipo = this.tipo
                model.id = id

                const {data} = await Publicidad.usarPublicidad(model)
                this.notificacion('Mensaje', data.mensaje, 'success')
                this.atras()

            } catch (e){
                this.error_catch(e);
            } finally {
                this.loading = false
            }
        },
        async guardarPublicidadCarpeta(){
            try {
                const valid = await this.$refs.form.validate()
                if(!valid) return
                this.loading = true
                let model = {}

                model.nombre = this.nombre_carpeta
                model.tipo = this.tipo
                model.id = null

                const {data} = await Publicidad.guardarPublicidadCarpeta(model)
                this.nombre_carpeta = ''
                this.notificacion('Mensaje', data.mensaje, 'success')
                data.model.publicidades = []
                this.carpetas.push(data.model)
                this.$refs.modalCrearCarpeta.toggle();

            } catch (e){
                this.error_catch(e);
            } finally {
                this.loading = false
            }
        },
        async eliminarPublicidad(){
            try {
                this.loading = true

                const {data} = await Publicidad.eliminarPublicidad(this.id_anuncio)
                this.notificacion('Mensaje', data.mensaje, 'success')
                this.$refs.modalEliminarImagen.toggle();
                let index = this.carpeta_activa.publicidades.findIndex(el => el.id == this.id_anuncio)
                this.carpeta_activa.publicidades.splice(index, 1)
                this.id_anuncio = null
            } catch (e){
                this.error_catch(e);
            } finally {
                this.loading = false
            }
        },
        async eliminarPublicidadCarpeta(){
            try {
                this.loading = true

                const {data} = await Publicidad.eliminarPublicidadCarpeta(this.carpeta_activa.id)
                this.notificacion('Mensaje', data.mensaje, 'success')
                this.$refs.modalEliminar.toggle();
                let index = this.carpetas.findIndex(el => el.id == this.carpeta_activa.id)
                this.carpetas.splice(index, 1)
                this.carpeta_activa = {
                    id: null,
                    nombre: null,
                    publicidades: [],
                }
            } catch (e){
                this.error_catch(e);
            } finally {
                this.loading = false
            }
        },
        editarCarpeta(){
            this.nombre_carpeta = this.carpeta_activa.nombre
            this.$refs.modalEditarCarpeta.toggle();
        },
        async editarPublicidadCarpeta(){
            try {
                const valid = await this.$refs.form.validate()
                if(!valid) return
                this.loading = true
                let model = {}

                model.nombre = this.nombre_carpeta
                model.tipo = this.tipo
                model.id = this.carpeta_activa.id

                const {data} = await Publicidad.editarPublicidadCarpeta(model)
                this.notificacion('Mensaje', data.mensaje, 'success')
                this.carpeta_activa.nombre = model.nombre
                this.nombre_carpeta = ''
                let index = this.carpetas.findIndex(el => el.id == data.model.id)
                this.$set(this.carpetas, index, data.model)
                this.$refs.modalEditarCarpeta.toggle();

            } catch (e){
                this.error_catch(e);
            } finally {
                this.loading = false
            }
        },
        eliminarCarpeta(){
            this.$refs.modalEliminar.toggle();
        },
        eliminarImagen(id){
            this.id_anuncio = id
            this.$refs.modalEliminarImagen.toggle();
        },
        atras(){
            let name = this.tipo == 1 ? 'admin.publicidad.imagenes-pop-up.clientes' : 'admin.publicidad.imagenes-pop-up.leecheros'

            this.$router.push({ name })
        },
        async usarPublicidadMultiple(){
            try {
                let idPublicidades = this.carpeta_activa.publicidades.filter(el => el.checked).map(el => el.id)
                if(!idPublicidades.length) return this.notificacion('', 'Debe seleccionar al menos una publicidad', 'error')

                let model = {}

                model.id_cedis = this.id_cedis
                model.tipo = this.tipo
                model.publicidades = idPublicidades
                const {data} = await Publicidad.usarPublicidadMultiple(model)
                this.notificacion('Mensaje', 'Guardado correctamente', 'success')
                this.atras()
            } catch (error){
                this.error_catch(error)
            }
        }
    }
}
</script>
