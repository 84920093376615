<template>
    <section>
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 184px);">
            <div class="d-flex mx-0">
                <div style="width:315px;">
                    <p class="text-general f-500 f-14 text-center">
                        Selecciona el tipo de usuario
                    </p>
                    <div class="row mx-0 my-3 justify-center">
                        <div class="col-auto d-middle">
                            <input v-model="tipoUsuario" :value="0" type="radio" class="option-input black radio" />
                            {{$config.vendedor}}
                        </div>
                        <div class="col-auto d-middle">
                            <input v-model="tipoUsuario" :value="1" type="radio" class="option-input black radio" />
                            {{$config.cliente}}
                        </div>
                    </div>
                    <div class="row mx-0 py-2">
                        <p class="text-general pl-4 f-14">
                            Fecha de analisis
                        </p>
                        <div class="col-10">
                            <el-date-picker
                            v-model="fecha"
                            class="w-100"
                            size="medium"
                            format="dd-MM-yyyy"
                            value-format="yyyy-MM-dd"
                            popper-class="date-picker-edit-popper"
                            type="daterange"
                            unlink-panels
                            start-placeholder="Fecha inicio"
                            end-placeholder="Fecha fin"
                            />
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="row mx-0 my-3" />
                    <el-select
                    v-model="idComparacion1"
                    filterable
                    clearable
                    remote
                    class="w-100"
                    reserve-keyword
                    :remote-method="data => remoteMethod(data, 1)"
                    >
                        <el-option
                        v-for="item in usuariosSelect1"
                        :key="item.id"
                        :label="`${item.nombre} - ${item.nombre_cedis}`"
                        :value="tipoUsuario == 0 ? item.created_by : item.id"
                        />
                    </el-select>
                    <div class="row mx-0 justify-center py-3">
                        <img :src="fotoUsurio(1)" width="62" height="62" class="obj-cover border rounded-circle br-50" alt="" />
                    </div>
                </div>
                <div class="col">
                    <div class="row mx-0 my-3" />
                    <el-select
                    v-model="idComparacion2"
                    filterable
                    clearable
                    remote
                    class="w-100"
                    reserve-keyword
                    :remote-method="data => remoteMethod(data, 2)"
                    >
                        <el-option
                        v-for="item in usuariosSelect2"
                        :key="item.id"
                        :label="`${item.nombre} - ${item.nombre_cedis}`"
                        :value="tipoUsuario == 0 ? item.created_by : item.id"
                        />
                    </el-select>
                    <div class="row mx-0 justify-center py-3">
                        <img :src="fotoUsurio(2)" width="62" height="62" class="obj-cover border rounded-circle br-50" alt="" />
                    </div>
                </div>
                <div class="col">
                    <div class="row mx-0 my-3" />
                    <el-select
                    v-model="idComparacion3"
                    filterable
                    clearable
                    remote
                    class="w-100"
                    reserve-keyword
                    :remote-method="data => remoteMethod(data, 3)"
                    >
                        <el-option
                        v-for="item in usuariosSelect3"
                        :key="item.id"
                        :label="`${item.nombre} - ${item.nombre_cedis}`"
                        :value="tipoUsuario == 0 ? item.created_by : item.id"
                        />
                    </el-select>
                    <div class="row mx-0 justify-center py-3">
                        <img :src="fotoUsurio(3)" width="62" height="62" class="obj-cover border rounded-circle br-50" alt="" />
                    </div>
                </div>
            </div>
            <div class="d-flex  py-2">
                <div class="" style="width:315px;">
                    <p class="text-general border-top  py-3 px-2 f-500 f-16" style="height:60px;">
                        {{ $config.cliente }} / {{ $config.vendedor }}
                    </p>
                    <p class="text-general border-top  py-3 px-2 f-500 f-16" style="height:60px;">
                        Primer Ingreso
                    </p>
                    <p class="text-general border-top  py-3 px-2 f-500 f-16" style="height:60px;">
                        Ingresó vía
                    </p>
                    <p class="text-general border-top  py-3 px-2 f-500 f-16" style="height:60px;">
                        Fecha primer pedido
                    </p>
                    <p class="text-general border-top  py-3 px-2 f-500 f-16" style="height:60px;">
                        Primer pedido vía
                    </p>
                    <p class="text-general border-top  py-3 px-2 f-500 f-16" style="height:60px;">
                        Valor del primer pedido
                    </p>
                    <p class="text-general border-top  py-3 px-2 f-500 f-16" style="height:60px;">
                        No. Primer pedido
                    </p>
                    <p class="text-general border-top  py-3 px-2 f-500 f-16" style="height:60px;">
                        Pedidos en el periodo
                    </p>
                    <p class="text-general border-top  py-3 px-2 f-500 f-16" style="height:60px;">
                        Valor en el periodo
                    </p>
                    <p class="text-general border-top  py-3 px-2 f-500 f-16" style="height:60px;">
                        Total pedidos
                    </p>
                    <p class="text-general border-top  py-3 px-2 f-500 f-16" style="height:60px;">
                        Valor total pedidos
                    </p>
                    <p class="text-general border-top  py-3 px-2 f-500 f-16" style="height:60px;">
                        Pedidos por la Web
                    </p>
                    <p class="text-general border-top  py-3 px-2 f-500 f-16" style="height:60px;">
                        Pedido por Android
                    </p>
                    <p class="text-general border-top  py-3 px-2 f-500 f-16" style="height:60px;">
                        Pedido por iOS
                    </p>
                    <p class="text-general border-top  py-3 px-2 f-500 f-16" style="height:60px;">
                        Creados por el {{ $config.vendedor }}
                    </p>
                    <p class="text-general border-top  py-3 px-2 f-500 f-16" style="height:60px;">
                        Último acceso
                    </p>
                    <p class="text-general border-top  py-3 px-2 f-500 f-16" style="height:60px;">
                        Últ. acceso Vía
                    </p>
                    <p class="text-general border-top  py-3 px-2 f-500 f-16" style="height:60px;">
                        Fecha último pedido
                    </p>
                    <p class="text-general border-top  py-3 px-2 f-500 f-16" style="height:60px;">
                        Último pedido vía
                    </p>
                    <p class="text-general border-top  py-3 px-2 f-500 f-16" style="height:60px;">
                        Valor del último pedido
                    </p>
                    <p class="text-general border-top  py-3 px-2 f-500 f-16" style="height:60px;">
                        No. Último pedido
                    </p>
                    <div class="row mx-0 border-top">
                        <div class="col-12 px-2 text-general f-600">
                            Pedidos últ. 6 meses
                        </div>
                        <div class="col-12 d-middle my-1">
                            <div class="bg-light-f5 mr-1" style="width:25px;height:25px;" />
                            No. de Pedidos
                        </div>
                        <div class="col-12 d-middle my-1">
                            <div class="bg-general3 mr-1 rounded-circle" style="width:25px;height:25px;" />
                            Dinero
                        </div>
                    </div>
                </div>
                <div class="col px-0">
                    <comparacion :data="userComparacion1" />
                </div>
                <div class="col px-0">
                    <comparacion :data="userComparacion2" />
                </div>
                <div class="col px-0">
                    <comparacion :data="userComparacion3" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import echarts from 'echarts'
import { mapGetters } from 'vuex'
import Accesos from "~/services/accesos_estadisticas"

Vue.component('echarts',echarts);
let colorGeneral = getComputedStyle(document.documentElement).getPropertyValue('--text-general')
let colorGeneral2 = getComputedStyle(document.documentElement).getPropertyValue('--color-db')

export default {
    components: {
        comparacion: () => import('./components/comparacion'),
    },
    data(){
        return{
            tipoUsuario: 0,
            idComparacion1: null,
            idComparacion2: null,
            idComparacion3: null,
            userComparacion1: {
                nombre: null,
                primer_acceso: null,
                via_primer_acceso: null,
                ultimo_acceso: null,
                via_ultimo_acceso: null,
                primer_pedido: null,
                valor_primer_pedido: null,
                via_primer_pedido: null,
                id_primer_pedido: null,
                total_pedidos: null,
                total_pedidos_fecha: null,
                pedidos_web: null,
                pedidos_android: null,
                pedidos_ios: null,
                pedidos_manuales: null,
                ventas: null,
                ventas_fechas: null,
                pedidos6Meses: null,
                ultimo_pedido: null,
                valor_ultimo_pedido: null,
                via_ultimo_pedido: null,
                id_ultimo_pedido: null,
            },
            userComparacion2: {
                nombre: null,
                primer_acceso: null,
                via_primer_acceso: null,
                ultimo_acceso: null,
                via_ultimo_acceso: null,
                primer_pedido: null,
                valor_primer_pedido: null,
                via_primer_pedido: null,
                id_primer_pedido: null,
                total_pedidos: null,
                total_pedidos_fecha: null,
                pedidos_web: null,
                pedidos_android: null,
                pedidos_ios: null,
                pedidos_manuales: null,
                ventas: null,
                ventas_fechas: null,
                pedidos6Meses: null,
                ultimo_pedido: null,
                valor_ultimo_pedido: null,
                via_ultimo_pedido: null,
                id_ultimo_pedido: null,
            },
            userComparacion3: {
                nombre: null,
                primer_acceso: null,
                via_primer_acceso: null,
                ultimo_acceso: null,
                via_ultimo_acceso: null,
                primer_pedido: null,
                valor_primer_pedido: null,
                via_primer_pedido: null,
                id_primer_pedido: null,
                total_pedidos: null,
                total_pedidos_fecha: null,
                pedidos_web: null,
                pedidos_android: null,
                pedidos_ios: null,
                pedidos_manuales: null,
                ventas: null,
                ventas_fechas: null,
                pedidos6Meses: null,
                ultimo_pedido: null,
                valor_ultimo_pedido: null,
                via_ultimo_pedido: null,
                id_ultimo_pedido: null,
            },
            value: '',
            ult6Meses: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    formatter: function(list){
                        var msg = "";
                        for (let i in list){
                            if (i == 0){ msg += '1 Jun. 2021' + "<br>"; }
                            msg += `<i class="icon-brightness f-18" style="color:${list[i].color};" />` + `<span class="text-white f-15">${list[i].data}</span> ` + ` ：<span class="text-white">${list[i].seriesName} </span>` + "<br>";
                            if (i > 0 && list[i].seriesName == "不可用余额" && list[i - 1].seriesName == "可用余额"){
                                msg += "总存款余额" + "：" + (list[i].data + list[i - 1].data) + "万元<br>";
                            }
                            if (i > 0 && list[i].seriesName == "支出" && list[i - 1].seriesName == "收入"){
                                msg += "净收入" + "：" + (list[i - 1].data - list[i].data) + "万元<br>";
                            }
                        }
                        return msg;
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    show: false,
                    axisLine: {
                        lineStyle: {
                            color: '#6D6B6B',
                            width: 1
                        }
                    },
                    data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
                },
                yAxis: {
                    axisLine: {
                        lineStyle: {
                            color: '#6D6B6B',
                            width: 1
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            color: "#AAAAAA56",
                        }
                    },
                    nameTextStyle: {
                        color: '#6D6B6B'
                    }
                },
                series: [
                    {
                        name: 'Usuarios que ingresaron',
                        type: 'bar',
                        barMaxWidth: 30,
                        data: [220, 182, 191, 234, 290, 330, 310],
                        itemStyle: {
                            normal: {
                                color: colorGeneral2
                            },
                        },
                    },
                    {
                        name: 'Interacciones Totales',
                        data: [213, 356, 123, 225, 78, 123, 354],
                        type: 'line',
                        itemStyle: {
                            color: colorGeneral,
                        },
                    },
                ]
            },
            usuariosSelect1: [],
            usuariosSelect2: [],
            usuariosSelect3: [],
            fecha: [moment().subtract(1, 'month').toDate(),new Date()],
        }
    },
    computed: {
        ...mapGetters({
            id_moneda:'cedis/id_moneda',
            id_cedis:'cedis/id_cedis',
        }),
        cargandoTerminado(){
            return  !this.graficasRangos &&
                    !this.cargandoInforme
        }
    },
    watch: {
        id_moneda(val,oldVal){
            if(oldVal == null) return
            this.cargando()
            this.montar()
        },
        id_cedis(val,oldVal){
            if(oldVal == null) return
            this.cargando()
            this.montar()
        },
        tipoUsuario(){
            this.chargeRemote()
        },
        idComparacion1(){
            this.comparativoAccesos(1)
        },
        idComparacion2(){
            this.comparativoAccesos(2)
        },
        idComparacion3(){
            this.comparativoAccesos(3)
        }
    },
    mounted(){
        this.montar()
    },
    methods: {
        async chargeRemote(){
            await this.remoteMethod('', 1)
            await this.limpiarSelect(1)
            await this.remoteMethod('', 2)
            await this.limpiarSelect(2)
            await this.remoteMethod('', 3)
            await this.limpiarSelect(3)
        },
        async limpiarSelect(tipo){
            this[`idComparacion${tipo}`] = ''
        },
        cargando(informes = true){
            if (informes){
                this.graficasRangos = true

            }
            this.cargandoInforme = true
            this.cargandoCriterios = true
        },
        async montar(){
            try {
                if(this.id_moneda === null && this.id_cedis == null){
                    setTimeout(async() => {
                        this.chargeRemote()
                    }, 1000);
                }else{
                    this.chargeRemote()
                }
            } catch (error){
                console.log(error)
            }
        },
        async remoteMethod(query, tipo = 1){
            let variable = `usuariosSelect${tipo}`
            try {
                let params = {
                    id_cedis: this.id_cedis,
                    query,
                    tipo: this.tipoUsuario == 0 ? 1 : 2,
                }
                const { data } = await Accesos.listaLeecheros(params)
                this[variable] = data
            } catch (e){
                this[variable] = []
            }
        },
        async comparativoAccesos(tipo = 1){
            let id = this[`idComparacion${tipo}`]
            if(id == '')return this.limpiarTipo(tipo)
            let variable = `userComparacion${tipo}`
            try {
                let params = {
                    fecha_inicio: moment(this.fecha[0]).format('Y-MM-DD'),
                    fecha_fin: moment(this.fecha[1]).format('Y-MM-DD'),
                    id_moneda: this.id_moneda,
                    id_cedis: this.id_cedis,
                    id,
                    tipo: this.tipoUsuario == 0 ? 1 : 2,
                }
                const { data } = await Accesos.comparativoAccesos(params)
                this[variable] = data.comparativo
            } catch (e){
                this[variable] = []
            }
        },
        fotoUsurio(tipo){
            let id = this[`idComparacion${tipo}`]
            let usuarios = this[`usuariosSelect${tipo}`]


            let usuario = usuarios.find(el => (this.tipoUsuario == 0 ? el.created_by : el.id) == id)
            return usuario ? usuario.imagen : '/img/no-imagen/sin_user.png'
        },
        limpiarTipo(tipo){
            let variable = `userComparacion${tipo}`
            this[variable] = {
                nombre: null,
                primer_acceso: null,
                via_primer_acceso: null,
                ultimo_acceso: null,
                via_ultimo_acceso: null,
                primer_pedido: null,
                valor_primer_pedido: null,
                via_primer_pedido: null,
                id_primer_pedido: null,
                total_pedidos: null,
                total_pedidos_fecha: null,
                pedidos_web: null,
                pedidos_android: null,
                pedidos_ios: null,
                pedidos_manuales: null,
                ventas: null,
                ventas_fechas: null,
            }
        }
    }
}
</script>
