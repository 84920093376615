<template>
    <section>
        <div class="row py-2 mx-0 px-5 bg-general text-white align-items-center justify-content-between">
            <img height="63" src="/img/modales/yourmarket_blanco.svg" alt="" />
            <p class="cr-pointer">Cerrar sesión</p>
        </div>
        <div class="d-middle bg-white" style="height: calc(100vh - 80px);">
            <div class="col-6 f-18 text-center mx-auto">
                <img height="150" src="/img/icons/emotes/cerezas.svg" alt="" />
                <p class="f-20 f-600 my-2">Su postulación actualmente está en proceso.</p>
                <p>"YourMarket" esta evaluando su postulación, De la mano de "YourMarket"</p>
                <p class="my-3">Cuidas a tu negocio y también a tu barrio</p>
                <div class="row mx-0 my-2 justify-center">
                    <div class="p-2 bg-general text-white cr-pointer br-10" style="width:300px;">
                        Cancelar postulación
                    </div>
                </div>
            </div>
            <div class="h-100 bg-whitesmoke" style="width:380px;">
                <chat />
            </div>
        </div>
    </section>
</template>

<script>
export default {
    layout: 'login'
}
</script>

<style lang="css" scoped>
</style>
