<template>
    <section>
        <div class="row py-2 mx-0 px-5 bg-grr-red text-white align-items-center justify-content-between">
            <img height="63" src="/img/modales/yourmarket_blanco.svg" alt="" />
            <p class="cr-pointer">Cerrar sesión</p>
        </div>
        <div class="d-middle-center bg-white" style="height: calc(100vh - 80px);">
            <div class="col-6 f-18 text-center">
                <img height="150" src="/img/icons/emotes/lemon.svg" alt="" />
                <p class="f-20 f-600 my-2 mt-3">Su postulación ha sido denegada por "YourMarket"</p>
                <p>Lastimosamente hemos determinado que no cumple con las condiciones o criterios de selección para ser parte de la comunidad "YourMarket".</p>
                <p class="my-3">Nos encantaría que afinaras detalles y te postularas nuevamente.</p>
                <div class="row mx-0 my-2 justify-center">
                    <div class="p-2 bg-grr-red text-white cr-pointer br-10" style="width:300px;">
                        Postularme nuevamente
                    </div>
                </div>
                <div class="row mx-0 my-2 justify-center">
                    <div class="p-2 bg-general text-white cr-pointer br-10" style="width:300px;">
                        Cancelar postulación
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    layout: 'login'
}
</script>

<style lang="css" scoped>
</style>
